import Template from 'core/ts/system/Template';
import TransitionController from 'core/ts/system/transition/TransitionController';
import { SimpleEventDispatcher, SignalDispatcher } from 'strongly-typed-events';
import { TemplateSwitcher } from 'core/ts/system/transition/TemplateAnimation';
import Component from 'core/ts/system/Component';
import ComponentNode from 'core/ts/system/ComponentNode';
import Path from 'core/ts/utils/Path';

export default class TemplateManager {
	private static _instance: TemplateManager;
	public static get Instance(): TemplateManager {
		return this._instance;
	}

	private _onNewTemplate = new SimpleEventDispatcher<Template>();
	public get onNewTemplate() {
		return this._onNewTemplate.asEvent();
	}

	private _onLoadingStart = new SignalDispatcher();
	public get onLoadingStart() {
	    return this._onLoadingStart.asEvent();
	}

	private _onLoadingDone = new SignalDispatcher();
	public get onLoadingDone() {
	    return this._onLoadingDone.asEvent();
	}

	private _onNewPath = new SignalDispatcher();
	public get onNewPath() {
	    return this._onNewPath.asEvent();
	}

	private _pageLoader: XMLHttpRequest = new XMLHttpRequest();

	private _isLoading: boolean = false;

	private _switcher: TemplateSwitcher;

	private _queuedLoadLinks: string[] = [];
	private _templateLayer: HTMLElement;

	private _latestLoadedNode: ComponentNode;
	private _latestLoadedTemplate: Template;

	private _currentTemplate: Template;

	private _currentUrl: string;
	private _urlChangeMode = '';

	private _routes: Route[] = [];

	public overrideQueuedTemplate: boolean = true;

	public static localCacheHash:string = null;

	constructor(templateLayer: HTMLElement, switcher: TemplateSwitcher) {
		if (TemplateManager._instance) {
			console.log("TemplateManager is men't to be a singleton. Only one instance is allowed at a time.");
		}
		this.setSwitcher(switcher);

		this._currentUrl = window.location.href;
		TemplateManager._instance = this;

		this._pageLoader = new XMLHttpRequest();
		window.onpopstate = this.onHashChanged;

		this._templateLayer = templateLayer;
	}

	public createBodyTemplate() {
        let route = this.getRoute(window.location.href.replace(Path.getBaseUrl(), ''));

        if(route) {
            let templateElement: Element = document.body.querySelector('.template');
            templateElement.parentElement.removeChild( templateElement );
            this.goto(route.url, 'replace');
        } else {
		    this.createNewTemplate(document.body, Path.getWellFormatedPath(window.location.href), false);
        }
    }

	public addRoute(route: Route) {
		this._routes.push(route);
	}

	public getCurrentTemplate(): Template {
		return this._currentTemplate;
	}

	public setSwitcher(switcher: TemplateSwitcher) {
		if (this._switcher) {
			this._switcher.kill();
		}
		this._switcher = switcher;
		this._switcher.setManager(this);
	}

	private onHashChanged = () => {
		this.goto(window.location.href, 'replace');
	};

	public goto(url: string, mode: string = 'push') {
		//Create router and check for routes here;

		this._urlChangeMode = mode;

		// console.log('url : ' + url);
		// console.log('mode : ' + mode);

		let route = this.getRoute(url);
		if (route && route.hardRedirect) {
			url = route.pointer;
		}

		if (this._currentUrl === url ) {
			return;
		}
		this._currentUrl = url;

		if (this._urlChangeMode === 'push') {
			history.pushState(url, url, url);
		} else if (this._urlChangeMode === 'replace') {
			history.replaceState(url, url, url);
		}

		if (route) {
			url = route.pointer;
		}

		this._onNewPath.dispatch();

		this._switcher.urlChange();

		if (!this._isLoading) {
			this.loadPage(url);
		} else {
			this.queueUrl(url, this.overrideQueuedTemplate);
		}
	}

	private getRoute(url: string): Route {
		const l = this._routes.length;
		for (let i = 0; i < l; i++) {
			if (this._routes[i].url.replace('/', '') === url.replace('/', '')) {
				return this._routes[i];
			}
		}
		return null;
	}

	private loadNextQueued(): void {
		if (this._queuedLoadLinks.length > 0) {
			this.loadPage(this._queuedLoadLinks.shift());

		}
	}

	private queueUrl(url: string, clearqueue: boolean = true): void {
		if (clearqueue) {
			this._queuedLoadLinks = [];
		}
		this._queuedLoadLinks.push(url);
	}

	public getUrlChangeMode():string {
		return this._urlChangeMode;
	}

	/**
	 * LOAD NEW TEMPLATE
	 * @params loadUrl
	 */
	private loadPage(loadUrl:string): void {
	    if(!this.isLoadingTemplate()) {
            this._onLoadingStart.dispatch();
        }

		this._isLoading = true;


	    let saved:string = this.getLocally(loadUrl);

	    if(saved) {
			let temp = document.createElement('html');
			temp.innerHTML = saved;
			this.createNewTemplate(temp, Path.getWellFormatedPath(loadUrl));
		} else {
			this._pageLoader.onreadystatechange = this.onPageLoaded;
			this._pageLoader.open('POST', loadUrl, true);
			this._pageLoader.setRequestHeader('Content-type', 'text/html');
			this._pageLoader.send();
		}
	}

	private onPageLoaded = () => {
		if (this._pageLoader.readyState === 4 && this._pageLoader.status === 200) {
			let temp = document.createElement('html');
			temp.innerHTML = this._pageLoader.responseText;

			this.saveLocally(this._pageLoader.responseURL, this._pageLoader.responseText);

			this.createNewTemplate(temp, Path.getWellFormatedPath(this._pageLoader.responseURL));
		}
	};

	private createNewTemplate(element: HTMLElement, path:string, clone: boolean = true) {
		let templateElement: Element = element.querySelector('.template');
		if (clone) {
		    try {
			    templateElement = templateElement.cloneNode(true) as Element;
            } catch( error ) {
		        console.error( element.innerHTML );
		        return;
            }
		}

        if(!this.isLoadingTemplate()) {
            this._onLoadingStart.dispatch();
        }
        this._isLoading = true;

		this._latestLoadedNode = Component.create( templateElement, null, false );
		this._latestLoadedTemplate = this._latestLoadedNode.getComponentByType<Template>( Template );
		this._latestLoadedTemplate.path = path;

		if (this._latestLoadedTemplate.isBuild()) {
			this.onNewTemplateReady();
		} else {
			this._latestLoadedTemplate.onBuild.one(this.onNewTemplateReady);
		}
	}

	/**
	 * Local storage
	 */
	private saveLocally(url:string, content:string):void {
		if(!TemplateManager.localCacheHash) {
			return;
		}

		// console.log('saveLocally();');

		let saveLocation:string = TemplateManager.localCacheHash + '_' +  Path.getWellFormatedPath(url);

		// console.log('saveLocation : ' + saveLocation);

		localStorage.setItem(saveLocation, content);
	}

	private getLocally(url:string):string {
		if(!TemplateManager.localCacheHash) {
			return null;
		}

		// console.log('getLocally();');

		let saveLocation:string = TemplateManager.localCacheHash + '_' +  Path.getWellFormatedPath(url);

		// console.log('saveLocation : ' + saveLocation);

		let saved:string = localStorage.getItem(saveLocation);
		if(saved) {
			console.log('url loaded from local storage');
		}
		return saved;
	}


	private onNewTemplateReady = () => {
	    // console.log('ON READY');
		this._switcher.addNewTemplate(this._latestLoadedTemplate);

        if(this.isLoadingTemplate() && this._queuedLoadLinks.length <= 0 ) {
            this._onLoadingDone.dispatch();
		    this._isLoading = false;
        }
		this.loadNextQueued();
	};

	public isLoadingTemplate() {
	    return this._isLoading;
    }


	/**
	 * ADD NEW TEMPLATE
	 * @params template
	 */
	public setTemplate(template: Template): TransitionController {
		const aniController = new TransitionController(template);

		this._currentTemplate = template;
		this._onNewTemplate.dispatch(template);
		this._templateLayer.style.visibility = 'visible';
		document.body.style.visibility = 'visible';

		ComponentNode.addChild( this._currentTemplate, this._templateLayer, false );
		this._latestLoadedNode.getComponents().forEach(sib => {
			sib.__tryAwake();
		});

		return aniController;
	}

	/**
	 * REMOVE TEMPLATE
	 * @params template
	 */
	public removeTemplate(template: Template): TransitionController {
		template.__tryUnload();
		const aniOutController = new TransitionController(template);
		aniOutController.onAllComplete.one(this.onOutAnimationDone);

		return aniOutController;
	}

	private onOutAnimationDone = (controller: TransitionController) => {
	    controller.rootModule.removeThis();
	};
}

export class Route {
	public readonly url: string;
	public readonly pointer: string;
	public readonly hardRedirect: boolean = false;

	constructor(url: string, pointer: string, hardRedirect: boolean = false) {
		this.url = url;
		this.pointer = pointer;
		this.hardRedirect = hardRedirect;
	}
}
