/**PLOP::IMPORT_LIST**/
import ImageGridSlideshow from 'modules/ImageGridSlideshow/ImageGridSlideshow';
import MediaGrid from 'modules/MediaGrid/MediaGrid';
import RemoveIfEmpty from 'modules/RemoveIfEmpty/RemoveIfEmpty';
import LineModule from 'modules/LineModule/LineModule';
import HideWhenNotInView from 'modules/HideWhenNotInView/HideWhenNotInView';
import ErrorModule from 'core/modules/ErrorModule/ErrorModule';
import Preloader from 'components/Preloader';
import FormingEssentialsModule from 'modules/FormingEssentialsModule/FormingEssentialsModule';
import FeaturedSlideshowImage from 'modules/FeaturedSlideshowImage/FeaturedSlideshowImage';
import MainMenuButton from 'components/MainMenuButton';
import FeaturedSlideshowModule from 'modules/FeaturedSlideshowModule/FeaturedSlideshowModule';
import ManifestModule from 'modules/ManifestModule/ManifestModule';
import TextLayout5Module from 'modules/TextLayout5Module/TextLayout5Module';
import FoundedByModule from 'modules/FoundedByModule/FoundedByModule';
import Contact2Module from 'modules/Contact2Module/Contact2Module';
import Contact1Module from 'modules/Contact1Module/Contact1Module';
import CoreCollapseModule from 'core/modules/CoreCollapseModule/CoreCollapseModule';
import RecognitionModule from 'modules/RecognitionModule/RecognitionModule';
import ClientListModule from 'modules/ClientListModule/ClientListModule';
import TextLayout4Module from 'modules/TextLayout4Module/TextLayout4Module';
import TextLayout3Module from 'modules/TextLayout3Module/TextLayout3Module';
import TextWithImageLayout1Module from 'modules/TextWithImageLayout1Module/TextWithImageLayout1Module';
import DefaultHeaderModule from 'modules/DefaultHeaderModule/DefaultHeaderModule';
import NewsModule from 'modules/NewsModule/NewsModule';
import MenuModule from 'modules/MenuModule/MenuModule';
import FooterModule from 'modules/FooterModule/FooterModule';
import HighlightedWorkModule from 'modules/HighlightedWorkModule/HighlightedWorkModule';
import ImageModule from 'modules/ImageModule/ImageModule';
import ImageWithTextRightModule from 'modules/ImageWithTextRightModule/ImageWithTextRightModule';
import TextLayout2Module from 'modules/TextLayout2Module/TextLayout2Module';
import DoubleImageModule from 'modules/DoubleImageModule/DoubleImageModule';
import CoreSlideshowModule from 'core/modules/CoreSlideshowModule/CoreSlideshowModule';
import VideoModule from 'modules/VideoModule/VideoModule';
import ImageWithTextLeftModule from 'modules/ImageWithTextLeftModule/ImageWithTextLeftModule';
import TextLayout1Module from 'modules/TextLayout1Module/TextLayout1Module';
import CaseHeroModule from 'modules/CaseHeroModule/CaseHeroModule';
import RootModule from 'core/modules/RootModule/RootModule';
import ImageLoadingModule from 'modules/ImageLoadingModule/ImageLoadingModule';
import ChildTransition from 'modules/PageTransitionModule/ChildTransition';
import PageTransitionModule from 'modules/PageTransitionModule/PageTransitionModule';
import MissingModule from 'core/modules/MissingModule/MissingModule';

import VarDump from 'components/Dumps/VarDump/VarDump';
import DefaultTemplate from "templates/DefaultTemplate";
import LazyLoadImage from "core/ts/utils/image/LazyLoadImage";
import Component from "core/ts/system/Component";
import DebugGridModule from "core/modules/DebugGridModule/DebugGridModule";
import DebugOverlayModule from "core/modules/DebugOverlayModule/DebugOverlayModule";
import SlideshowPageInfo from "core/modules/CoreSlideshowModule/SlideshowPageInfo";
import SlideshowComponent from '../core/modules/CoreSlideshowModule/SlideshowComponent';
import ColorItem from "components/ColorItem";
import ColorItemBackgroundBackground from "components/ColorItemBackgroundBackground";
import ColorItemTextToForeground from "components/ColorItemTextToForeground";
import ColorItemBorderBottomToForeground from "components/ColorItemBorderBottomToForeground";
import ColorItemBackgroundToForeground from "components/ColorItemBackgroundToForeground";
import ColorItemBorderTopToForeground from "components/ColorItemBorderTopToForeground";
import ColorItemFillToForeground from "components/ColorItemFillToForeground";
import ColorItemStrokeToForeground from "components/ColorItemStrokeToForeground";
import LinkHit from "core/components/LinkHit";
import CrazyLines from 'components/CrazyLines';
import ErrorTemplate from 'templates/ErrorTemplate';
import CopySlideInfo from "core/modules/CoreSlideshowModule/CopySlideInfo";
import ColorItemCanvasStrokeToForeground from "components/ColorItemCanvasStrokeToForeground";
import PreloaderIcon from "components/PreloaderIcon";
import ScaleFontToParentWidth from "core/components/ScaleFontToParentWidth";
import CursorTarget from "core/components/CursorTarget";
import DefaultSlideshowModule from "modules/DefaultSlideshowModule/DefaultSlideshowModule";
import Pixelate from "components/Pixelate";
import ImgixImage from 'core/components/ImgixImage';
import CoreVideoModule from "core/modules/CoreVideoModule/CoreVideoModule";
import WindowInnerHeight from "core/components/WindowInnerHeight";
import Cursor from "core/ts/utils/cursor/Cursor";
import CursorTargetLink from "core/components/CursorTargetLink";
import VimeoComponent from "modules/VimeoComponent/VimeoComponent";

// @ts-ignore
export const Modules: any = {
    /**PLOP::CLASS_LIST**/
    ImageGridSlideshow,
    MediaGrid,
    RemoveIfEmpty,
    LineModule,
    HideWhenNotInView,
    ErrorModule,
    DefaultSlideshowModule,
    Preloader,
    PreloaderIcon,
    FormingEssentialsModule,
    FeaturedSlideshowImage,
    MainMenuButton,
    FeaturedSlideshowModule,
    ManifestModule,
    TextLayout5Module,
    FoundedByModule,
    Contact1Module,
    Contact2Module,
    CoreCollapseModule,
    RecognitionModule,
    ClientListModule,
    TextLayout4Module,
    TextLayout3Module,
    TextWithImageLayout1Module,
    DefaultHeaderModule,
    NewsModule,
    MenuModule,
    FooterModule,
    HighlightedWorkModule,
    ImageModule,
    ImageWithTextRightModule,
    TextLayout2Module,
    DoubleImageModule,
    CoreSlideshowModule,
    SlideshowComponent,
    SlideshowPageInfo,
    CopySlideInfo,
    VideoModule,
    CoreVideoModule,
    ImageWithTextLeftModule,
    TextLayout1Module,
    CaseHeroModule,
    RootModule,
    CursorTarget,
    DebugGridModule,
    DebugOverlayModule,
    LazyLoadImage,
    Pixelate,
    ScaleFontToParentWidth,
    LinkHit,
    ColorItem,
    ColorItemBackgroundBackground,
    ColorItemBackgroundToForeground,
    ColorItemTextToForeground,
    ColorItemBorderTopToForeground,
    ColorItemBorderBottomToForeground,
    ColorItemFillToForeground,
    ColorItemStrokeToForeground,
    ColorItemCanvasStrokeToForeground,
    ImageLoadingModule,
    ChildTransition,
    PageTransitionModule,
    MissingModule,
	CrazyLines,
	ErrorTemplate,
    VarDump,
	DefaultTemplate,
	ImgixImage,
    WindowInnerHeight,
    Cursor,
    CursorTargetLink,
    VimeoComponent
};

/**
 * @ignore
 */
export default class AllModules {
}