//Import for Webpack
import './ImageLoadingModule.scss';

import Component from 'core/ts/system/Component';
import Module from 'core/ts/system/Module';
/**
 * @ignore
 */
export default class ImageLoadingModule extends Module {
	/**
	 * Template and modules will never be added or __tryBuild before all nested modules are loaded
	 * If you need something loaded before this and all childmodules gets __tryBuild() and added to the dom. This is where you do it.
	 * Call super.__tryLoad() when you are done loading. (Dont forget this, also make sure to listen for fail and call super).
	 *
	 * --This will only ever get called once.
	 */
	protected load(): void {
        // console.log('__tryLoad');
	}

	/**
	 * Called when this and all nested modules are loaded.
	 * Use this function to setup and manipulate the dom.
	 *
	 * --This will only ever get called once.
	 */
	protected build(): void {
	    // console.log('__tryBuild');
	}

	private awakeCount:number = 0;

	/**
	 * Called right before element is added to the dom
	 * Add eventlisteners and prepare/init the component
	 */
	protected awake(): void {
        // let alModules:Component[] = this.getAllChildTypes() as Component[];
		// let images:CloudImage[] = this.getAllChildTypes('CloudImage') as CloudImage[];
		// console.log(images.length);
	}


	/**
	 * Called right before element gets removed from the dom
	 * Remove listeners and __kill stuff
	 */
	//protected sleep(): void {
	//    super.sleep();
	//}
}
