import './DebugOverlayModule.scss';

import Component from "core/ts/system/Component";
import App from 'ts/app';
import Module from "core/ts/system/Module";
import Template from 'core/ts/system/Template';


export default class DebugOverlayModule extends Component {
	private _visible: Boolean = false;

	private _images: HTMLImageElement[] = null;
	private _htmlElements: HTMLElement[];
	private _resizeItems: HTMLElement[];
	private _components: Component[];

	private _layouts: number[] = [375, 1600];

	private _activeBreakpoint: number;

	private _currentTemplate:Template;


	protected awake() {

		document.addEventListener('keydown', this.onKeyDown);

		App.RESIZE.onResize.sub(this.onResize);

		console.log('window.localStorage.getItem(\'DebugOverlayModule_visible\') : ' + window.localStorage.getItem('DebugOverlayModule_visible'));
		this._currentTemplate = App.CONTENT.getCurrentTemplate();
		App.CONTENT.onNewTemplate.sub( this.onNewTemplate );

		console.log(this._currentTemplate);

		if(window.localStorage.getItem('DebugOverlayModule_visible') == 'true') {
			this.showPreviews();
		}

	}

	protected sleep() {
		document.removeEventListener('keydown', this.onKeyDown);
		App.RESIZE.onResize.unsub(this.onResize);
		App.CONTENT.onNewTemplate.unsub( this.onNewTemplate );
	}

	private onResize = () => {
		if(this._visible) {
			this.showPreviews();
		}
	};

	private onKeyDown = (e: KeyboardEvent) => {
		if(e.key === 'p') {
			if(!this._visible) {
				this.showPreviews();
			} else {
				this.hidePreviews();
			}

			window.localStorage.setItem('DebugOverlayModule_visible', this._visible == true ? 'true' : 'false');
		}
	};

	private onNewTemplate = ( template:Template ) => {
		let reloadFromCookie = false;
		if(!this._currentTemplate) {
			reloadFromCookie = true;
		}
		this._currentTemplate = template;

		if(reloadFromCookie) {
			if(window.localStorage.getItem('DebugOverlayModule_visible') == 'true') {
				this.showPreviews();
			}
		}
		// console.log('NEW TEMPALTE ' + template);
	};

	private getActiveBreakpoint() {
		let index = 0;
		this._layouts.forEach(function (value) {
			if(App.RESIZE.windowWidth - 100 >= value) {
				index += 1;
			}
		});

		const max = this._layouts.length - 1;
		if(index > max) {
			index = max;
		}

		return this._layouts[index];
	}

	private showPreviews() {
		if(!this._currentTemplate) {
			return;
		}

		// console.log('DebugOverlayModule.showPreviews();');
		this._visible = true;

		let newBreakpoint = this.getActiveBreakpoint();


		if(newBreakpoint == this._activeBreakpoint) {
			return;
		}

		console.log('newBreakpoint : ' + newBreakpoint);

		this._activeBreakpoint = newBreakpoint;

		this.revertToOriginalSize();
		this.removePreviews();

		this._images = [];
		this._htmlElements = [];
		this._components = [];

		let modules = this._currentTemplate.getComponentsByType<Module>(Module, false);
		let numberOfModules = modules.length;
		// console.log('numberOfModules : ' + numberOfModules);

		for(let i = 0; i < numberOfModules; i += 1) {
			const module = modules[i];

			// console.log('module : ' + module);

			this._components.push(module);

			let image = document.createElement('img');
			image.src = 'core/modules/DebugOverlayModule/Previews/' + module.toString().split(', ')[0] + "_" + this._activeBreakpoint + 'px.png?u=' + Math.random();
			image.style.zIndex = '999999';
			image.style.position = 'absolute';
			image.style.top = '0px';
			image.style.pointerEvents = 'none';
			image.width = this._activeBreakpoint;
			image.style.left = '0px';
			image.style.opacity = '.5';
			image.style.borderBottom = '1px dashed red';
			image.onerror = this.onError;
			module.getElement().appendChild(image);

			this._images.push(image);

			this._htmlElements.push(module.getElement());
			module.getElement().style.maxHeight = 'none';

			let children = module.getElement().querySelectorAll('*');
			children.forEach(el => {
				if(el instanceof HTMLElement) {
					(el as HTMLElement).style.maxHeight = 'none';
				}
			});
		}

		this._resizeItems = [];

		let children = this._currentTemplate.getElement().children;
		let numberOfRootElements = children.length;
		for (let i = 0; i < numberOfRootElements; i += 1) {
			const child = children[i] as HTMLElement;
			this._resizeItems.push(child);

			child.style.width = this._activeBreakpoint + 'px';

		}

		//TODO: This is a Quickfix. Should be move to a listener.
		const debugGrid:HTMLElement = document.body.querySelector('.DebugGridModule');
		if(debugGrid) {
			debugGrid.style.width = this._activeBreakpoint + 'px';
		}
	}

	private onError = (e: any) => {
		let image = e.currentTarget as HTMLImageElement;

		console.warn('Preview not found - ' + image.src);
	};

	private hidePreviews() {
		// console.log('DebugOverlayModule.hidePreviews();');

		this._visible = false;

		this.revertToOriginalSize();
		this.removePreviews();

		this._activeBreakpoint = - 1;
	}

	private revertToOriginalSize() {
		if(this._resizeItems == null) {
			return;
		}
		let numberOfResizeItems = this._resizeItems.length;
		for (let i = 0; i < numberOfResizeItems; i += 1) {
			const item = this._resizeItems[i];
			item.style.width = null;
		}
		this._resizeItems = null;

		let numberOfModules = this._htmlElements.length;
		for (let i = 0; i < numberOfModules; i += 1) {
			const item = this._htmlElements[i];
			item.style.maxHeight = null;

			let children = item.querySelectorAll('*');
			children.forEach(el => {
				if(el instanceof HTMLElement) {
					(el as HTMLElement).style.maxHeight = null;
				}
			});
		}

		//TODO: This is a Quickfix. Should be move to a listener.
		const debugGrid:HTMLElement = document.body.querySelector('.DebugGridModule');
		if(debugGrid) {
			debugGrid.style.width = null;
		}
		this._htmlElements = null;
	}

	private removePreviews() {
		if(this._images == null) {
			return;
		}
		let l = this._images.length;
		for(let i = 0; i < l; i += 1) {
			let image = this._images[i];
			image.parentNode.removeChild(image);
		}

		this._images = null;
	}
}