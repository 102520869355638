import LazyLoadImage from "core/ts/utils/image/LazyLoadImage";
import Path from 'core/ts/utils/Path';

export default class ImgixImage extends LazyLoadImage {
	public static QUALITY:string = '75';

	protected getLoadUrl():string {
		let imgUrl:string = Path.genFullUrl( this.params.plain.src );

		if( Path.isLocal( imgUrl ) ){
			return this.params.plain.src;
		}

		let command = '';
		// if( res.isAutoHeight() ) {
		// 	command = 'w=' + res.width + '&q=' + ImgixImage.QUALITY
		// } else {

		// console.log('imgUrl : ' + imgUrl);

		if(imgUrl.endsWith('.gif')) {
			// command = 'w=' + this.getWidthToLoad() + '&h=' + this.getHeightToLoad() + '&q=100';
			command = '';
		} else {
			command = 'w=' + this.getWidthToLoad() + '&h=' + this.getHeightToLoad() + '&q=' + ImgixImage.QUALITY + '&auto=format';
		}

		// }

		let fullUrl:string = imgUrl + (command !== '' ? '?' + command : '');

		return fullUrl;
	}
}