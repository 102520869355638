import { IColumn } from './IColumn';

export interface ISettings {
	width:number;
	numberOfColumns:number;
	gutter:number;
	ySpacing?:number;
	blockedColumns?:boolean[];
	fixMisalignmentsTreshold?:number;
	closeSmallGapsIfOverlappingToTheLeft?:boolean;
	alignWithNewLine?:boolean;
	closeSmallerGapsTreshold?:number;
}

export class Grid {
	private _columns: IColumn[] = [];

	private _settings:ISettings | undefined = undefined;
	private _columnWidth: number;

	private _items: HTMLElement[] = [];

	constructor() {}

	
	/**
	 * Adding items
	 */
	public addItem(item: HTMLElement): void {
		this._items.push(item);

		if (this._settings === undefined) {
			return;
		}

		this.positionItem(item);
	}


	/**
	 * Resizing
	 */
	public resize(settings:ISettings): void {
		this._settings = settings;

		// console.log("");
		// console.log("Grid.resize(); " + JSON.stringify(this._settings));

		if(this._settings.ySpacing === undefined) {
			this._settings.ySpacing = this._settings.gutter;
		}

		let accumulatedGutterWidth: number = this._settings.gutter * (this._settings.numberOfColumns - 1);
		this._columnWidth = (this._settings.width - accumulatedGutterWidth) / this._settings.numberOfColumns;

		this._columns = [];

		for (let i: number = 0; i < this._settings.numberOfColumns; i += 1) {
			this._columns[i] = { height: 0, index: i, blocked: this.isColumnBlocked(i) };
		}

		this.preResize();

		this._items.forEach((item: HTMLElement): void => {
			this.positionItem(item);
		});

		this.postResize();
	}


	private preResize():void {
		// console.log("Grid.preResize();");

		const l = this._items.length;

		for(let i = 0; i < l; i += 1) {
			const item:HTMLElement = this._items[i];
			
			// reset the styling
			item.style.height = null;
			item.style.border = null;

			const img:HTMLImageElement = item.querySelector('img');
			// if(img) {
			// 	img.style.width = null;
			// 	img.style.height = null;
			// 	img.style.objectFit = null;
			// }
		}
	}

	private postResize():void {
		// console.log("Grid.postResize();");

		if(this._settings.numberOfColumns <= 1) {
			return;
		}

		if(this._settings.fixMisalignmentsTreshold && this._settings.fixMisalignmentsTreshold > 0) {
			const l = this._items.length;
	
			// reposition
			for(let i = 0; i < l; i += 1) {
				const item:HTMLElement = this._items[i];
				
				const otherItem:HTMLElement | undefined = this.getTheFurthestStartItemOnYAxis(item, this._settings.fixMisalignmentsTreshold);
				
				if(otherItem === undefined) continue;
				
				item.style.top = otherItem.offsetTop + 'px';
			}

			// resize height
			for(let i = 0; i < l; i += 1) {
				const item:HTMLElement = this._items[i];
				
				const otherItem:HTMLElement | undefined = this.getTheClosestEndItemOnYAxis(item, this._settings.fixMisalignmentsTreshold);
				
				if(otherItem === undefined) continue;
				
				const yEnd = item.offsetTop + item.offsetHeight;
				const otherYEnd = otherItem.offsetTop + otherItem.offsetHeight;
				const dist:number = otherYEnd - yEnd;
	
				item.style.height = item.offsetHeight + dist + "px";
				
				const img:HTMLImageElement = item.querySelector('.grid-image');

				// console.log('img : ' + img);

				if(img) {
					img.style.objectFit = 'cover';
					img.style.width = '100%';
					img.style.height = '100%';
				}
			}
		}
	}

	private getTheClosestEndItemOnYAxis(item:HTMLElement, distTreshold:number = 10):HTMLElement | undefined {
		const l = this._items.length;
		const yEnd = item.offsetTop + item.offsetHeight;
		let bestHit:HTMLElement | undefined = undefined;
		let maxDist = 0;

		for(let i = 0; i < l; i += 1) {
			const otherItem:HTMLElement = this._items[i];

			if(otherItem === item) continue;

			const otherYEnd = otherItem.offsetTop + otherItem.offsetHeight;

			const dist:number = otherYEnd - yEnd;

			if(dist < 0) continue;

			if(dist > distTreshold) continue;

			if(dist < maxDist) continue;

			bestHit = otherItem;
			maxDist = dist;
		}

		return bestHit;
	}

	private getTheFurthestStartItemOnYAxis(item:HTMLElement, distTreshold:number = 10):HTMLElement | undefined {
		const l = this._items.length;
		const yStart = item.offsetTop;
		let bestHit:HTMLElement | undefined = undefined;
		let maxDist = 0;

		for(let i = 0; i < l; i += 1) {
			const otherItem:HTMLElement = this._items[i];

			if(otherItem === item) continue;

			const otherStart = otherItem.offsetTop;

			const dist:number = otherStart - yStart;

			if(dist < 0) continue;

			if(dist > distTreshold) continue;

			if(dist < maxDist) continue;

			bestHit = otherItem;
			maxDist = dist;
		}

		return bestHit;
	}

	private positionItem(item: HTMLElement): void {
		if (item.classList.contains('first')) {
			item.classList.remove('first');
		}

		let itemWidth: number = item.offsetWidth;
		let itemHeight: number = item.offsetHeight;

		let columnSpan: number = Math.round(itemWidth / (this._columnWidth + this._settings.gutter));
		let possibleColumns: Array<any> = this.getAllPossibleComlumns(columnSpan);

		possibleColumns = possibleColumns.sort(function(a: any, b: any) {
			return a.startY - b.startY;
		});

		let startXIndex: number = possibleColumns[0].startXIndex;
		let startY: number = possibleColumns[0].startY;
		let highestColumn:number = this.getHighestColumnInSpan(0, this._settings.numberOfColumns);;

		// align with new line
		if(this._settings.alignWithNewLine === true) {
			if(startXIndex === 0) {
				for (let index = 0; index < this._settings.numberOfColumns; index += 1) {
					this._columns[index].height = highestColumn;
				}
			}
		}

		// close the small gaps if item is overlapping something to left
		if(this._settings.closeSmallGapsIfOverlappingToTheLeft === true) {
			if(startXIndex !== 0) {
				if(startY + itemHeight > highestColumn) {
					startXIndex = 0;
					startY = highestColumn;
	
					for (let index = 0; index < this._settings.numberOfColumns; index += 1) {
						this._columns[index].height = highestColumn;
					}
				}
			}
		}

		// are the sorounding columns aligned (close smaller gaps)
		if(this._settings.closeSmallerGapsTreshold !== undefined) {
			if(this._settings.numberOfColumns > 2) {
				var accHeightwithoutTarget = 0;
				for (let index = 0; index < this._settings.numberOfColumns; index += 1) {
					if(index === startXIndex) continue;
		
					accHeightwithoutTarget += this._columns[index].height;
				}
				
				var avgHeightwithoutTarget = accHeightwithoutTarget / (this._settings.numberOfColumns - 1);
				var deltaHeight = avgHeightwithoutTarget - this._columns[startXIndex].height;
		
				if(
					deltaHeight > 0 && 
					deltaHeight < this._columnWidth * this._settings.closeSmallerGapsTreshold
				) {
					startY = highestColumn;
		
					for (let index = 0; index < this._settings.numberOfColumns; index += 1) {
						this._columns[index].height = highestColumn;
					}
				}
			}
		}

		// console.log('picked : ' + startXIndex);
		let startColumn: IColumn = this._columns[startXIndex];

		// console.log('columnSpan : ' + columnSpan);

		let gutterOffset: number = 0;
		if (startXIndex !== 0) {
			gutterOffset = this._settings.gutter * startXIndex;
		}

		let startX: number = startXIndex * this._columnWidth + gutterOffset;

		// console.log('gutterOffset : ' + gutterOffset);
		
		var isFirst = false;
		if (startColumn.height === 0) {
			item.classList.add('first');
			isFirst = true;
		}

		// console.log('startX : ' + startX);
		// console.log('startY : ' + startY);

		if(!isFirst) {
			startY += this._settings.ySpacing;
		}

		item.style.position = 'absolute';
		item.style.left = startX + 'px';
		item.style.top = startY + 'px';

		let endY: number = startY + itemHeight;

		// console.log('endY : ' + endY);
		// console.log('columnSpan : ' + columnSpan);

		for (let spanIndex = 0; spanIndex < columnSpan; spanIndex += 1) {
			let index: number = startXIndex + spanIndex;

			// console.log(index + ' - ' + endY);
			this._columns[index].height = endY;
		}
	}


	/**
	 * Helpers
	 */
	private isColumnBlocked(index:number):boolean {
		if(!this._settings.blockedColumns) {
			return false;
		}

		if(!this._settings.blockedColumns) {
			return false;
		}

		if(index >= this._settings.blockedColumns.length) {
			return false;
		}

		return this._settings.blockedColumns[index] === true;
	}

	private getAllPossibleComlumns(columnSpan: number): Array<any> {
		let fits = true;
		let columnHeightArray: Array<IColumn> = this.getColumnHeightClone();
		let possibleColumns: Array<any> = new Array<any>();

		for (let i: number = 0; i < this._settings.numberOfColumns; i += 1) {
			let column: IColumn = columnHeightArray[i];
			let columnIndex: number = column.index;
			let startColumnHeight: number = column.height;

			fits = true;

			if (columnIndex + columnSpan > this._settings.numberOfColumns) {
				fits = false;
			}

			if (fits === true) {
				for (let spanIndex = 0; spanIndex < columnSpan; spanIndex += 1) {
					let checkIndex: number = columnIndex + spanIndex;
					// console.log('checkIndex : ' + checkIndex);
					let spanColumn: IColumn = this._columns[checkIndex];
					if (spanColumn.blocked === true) {
						fits = false;
					}
				}
			}

			// if (fits === true) {
			// 	for (let spanIndex = 0; spanIndex < columnSpan; spanIndex += 1) {
			// 		let checkIndex: number = columnIndex + spanIndex;
			// 		// console.log('checkIndex : ' + checkIndex);
			// 		let spanColumn: IColumn = this._columns[checkIndex];
			// 		let spanColumnHeight: number = spanColumn.height;
			//
			// 		if (spanColumnHeight > startColumnHeight) {
			// 			fits = false;
			// 			break;
			// 		}
			// 	}
			// }

			if (fits == true) {
				possibleColumns.push({ startXIndex: columnIndex, startY: this.getHighestColumnInSpan(columnIndex, columnSpan) });
			}
		}

		return possibleColumns;
	}

	private getHighestColumnInSpan(startIndex: number, span: number): number {
		const endIndex: number = startIndex + span;
		let maxHeight: number = 0;

		for (let index = startIndex; index < endIndex; index += 1) {
			let currHeight: number = this._columns[index].height;

			if (currHeight > maxHeight) {
				maxHeight = currHeight;
			}
		}

		return maxHeight;
	}

	public getHeight(): number {
		return this.getColumnHeightClone()[this._settings.numberOfColumns - 1].height;
	}

	private getColumnHeightClone(): Array<any> {
		let clone: Array<any> = new Array<any>();

		this._columns.forEach((item: any): void => {
			clone.push({ height: item.height, index: item.index });
		});

		return clone.sort(function(a: IColumn, b: IColumn) {
			return a.height - b.height;
		});
	}
}
