import Template from "core/ts/system/Template";
import ColorManager from "ts/ColorManager";

/**
 * @ignore
 */
export default class DefaultTemplate extends Template {
	private _isAnimatingOut: boolean = false;

    protected awake(): void {
		// TweenMax.set(this.getElement(), {
		// 	x: App.RESIZE.windowWidth
		// });

		window.scrollTo(0, 0);

		// console.log('this.params.plain.backgroundcolor : ' + this.params.plain.backgroundcolor);
		// console.log('this.params.plain.foregroundcolor : ' + this.params.plain.foregroundcolor);

		ColorManager.Instance.backgroundColor = this.params.plain.backgroundcolor;
		ColorManager.Instance.foregroundColor = this.params.plain.foregroundcolor;

		// ColorManager.Instance.backgroundColor = "#ff0000";
		// ColorManager.Instance.foregroundColor = "#ffffff";

		// ColorManager.Instance.backgroundColor = this.getRandomColor();
		// ColorManager.Instance.foregroundColor = this.getRandomColor();

		// this.showTestMargin();
	}

	private showTestMargin():void {
		const l = this.getElement().childNodes.length;

		for(let i = 0; i < l; i += 1) {
			let element:HTMLElement = this.getElement().childNodes[i] as HTMLElement;
			// console.log('element.tagName : ' + element.tagName);

			if(element.tagName !== 'SECTION') {
				continue;
			}

			let newElement = document.createElement('div');
			newElement.classList.add('test');
			element.prepend(newElement);
		}
	}

	private getRandomColor() {
		var color = "";
		for(var i = 0; i < 3; i++) {
			var sub = Math.floor(Math.random() * 256).toString(16);
			color += (sub.length == 1 ? "0" + sub : sub);
		}
		return "#" + color;
	}

	// public transitionIn(controller: Transition): void {
	// 	TweenMax.to(this.getElement(), 1.5, {
	// 		x: 0,
    //         ease:Expo.easeInOut,
	// 		onComplete: () => {
    //             controller.done();
	// 		}
	// 	});
	// }
	//
	// public transitionOut(controller: Transition): void {
	// 	this._isAnimatingOut = true;
	// 	TweenMax.to(this.getElement(), 1.5, {
	// 		x: -App.RESIZE.windowWidth,
    //         ease:Expo.easeInOut,
	// 		onComplete: () => {
	// 		    controller.done();
	// 		}
	// 	});
	// }


	public scrollTo(yScroll: number):void {
		//Ignore scroll when animating out;
		if (this._isAnimatingOut) {
			return;
		}

		super.scrollTo(yScroll);
	}

}