import Component from "core/ts/system/Component";
import CursorTarget from "core/components/CursorTarget";

export default class CursorTargetLink extends CursorTarget {
    protected getCursorName():string {
        return 'link';
    }

    protected getSortIndex():number {
        return 1;
    }
}