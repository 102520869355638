//Import for Webpack
import './VideoModule.scss';

import Module from 'core/ts/system/Module';
import SlideshowComponent from "core/modules/CoreSlideshowModule/SlideshowComponent";
import {SignalDispatcher} from 'strongly-typed-events';
import Pixelate from "components/Pixelate";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import CoreVideoModule from "core/modules/CoreVideoModule/CoreVideoModule";

export default class VideoModule extends CoreVideoModule {
    // private _pixelateEffect:Pixelate = null;
    //
    // protected build(): void {
    //     this._pixelateEffect = this.getComponentByType<Pixelate>(Pixelate);
    //     super.build();
    // }

    // protected isReadyForPlay():boolean {
    //     if(this._pixelateEffect === null) {
    //         return true;
    //     }
    //     return this._pixelateEffect.isEffectDone();
    // }
}