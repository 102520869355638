//Import for Webpack
import Module from 'core/ts/system/Module';

import { TweenLite, Expo } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import 'core/lib/greensock/ThrowPropsPlugin';
import App from 'ts/app';
import {SignalDispatcher} from 'strongly-typed-events';
import SlideshowComponent from "core/modules/CoreSlideshowModule/SlideshowComponent";
import Timeout = NodeJS.Timeout;
import {StaticWrapper} from 'core/ts/system/static/StaticWrapper';
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";


/**
 * Usage
 *
 * data-slideclass target via params.plain.slideclass
 * data-autoTime - auto pagination in seconds
 *
 */
export default class CoreSlideshowModule extends Module {
    private _slideContainer:HTMLElement & {[k:string]:any };
    private _slideTrigger:HTMLElement;
    private _slides:Array<HTMLElement>;

    private _itemWidth: number;
    private _totalWidth: number;
    private _numOfSlides: number;
    protected offsetSize: number;

    private offsetItem: number = 0;

    private _dragger: Draggable;

    private _onUpdate = new SignalDispatcher();

    private _slidesClass:string;
    private _autoTime:number;

    private _autoTimeInterval:Timeout = null;

    private _staticWrapper:StaticWrapper;

    private _renderItem:RenderItem = null;
    private _isInView:boolean = false;

    private _hasInteracted:boolean = false;

    public get onUpdateEvent() {
        return this._onUpdate.asEvent();
    }

    protected build(): void {
        let components:SlideshowComponent[] = this.getComponentsByType<SlideshowComponent>(SlideshowComponent, false);

        components.forEach(component => {
            component.SetSlideshow(this);
        });

        this._slidesClass = this.params.plain.slideclass ? '.' + this.params.plain.slideclass : '.Module';
        this._autoTime = this.params.plain.autotime ? Number.parseFloat(this.params.plain.autotime) : - 1;

        // console.log('this._autoTime : ' + this._autoTime);

        this._slideContainer = this.qs('.container');
        this._slideTrigger = this.qs('.trigger');
        this._slides = this.getSlides();
        this._numOfSlides = this._slides.length;

        this._staticWrapper = new StaticWrapper(this);
        // super.build();
    }

    private getSlides():Array<HTMLElement> {
        let array:Array<HTMLElement> = new Array<HTMLElement>();
        let raw:NodeListOf<HTMLElement> = this._slideContainer.querySelectorAll(this._slidesClass);
        const l:number = raw.length;

        for(let i:number = 0; i < l; i += 1) {
            let element:HTMLElement = raw[i] as HTMLElement;

            if(element.parentNode === this._slideContainer) {
                array.push(element);
            }
        }

        return array;
    }

    protected awake(): void {
        App.RESIZE.onResize.sub(this.updateDragger);
        this.updateDragger();

        for (let i = 0; i < this._numOfSlides; i++) {
            this._slides[i].addEventListener('click', this.onSlideClick);
        }

        super.awake();
        
        if(this.params.plain['start-index']) {
            // console.log("this.params.plain['start-index'] : " + this.params.plain['start-index']);
            this.gotoIndex(parseInt(this.params.plain['start-index']), 0);
        }

        this._renderItem = RenderLoop.Instance.add(this.render, 2);
    }

    private render = (deltaTime:number, totalTime:number):void => {
        this.viewInfo.updateRect();

        if (this.viewInfo.isInView() === true) {
            this.becameVisible();
        } else {
            this.becameNotVisible();
        }
    };

    private becameVisible():void {
        if(this._isInView === true) {
            return;
        }
        this._isInView = true;

        if(this._hasInteracted === false) {
            if(this._autoTime != - 1) {
                this._autoTimeInterval = setInterval(this.gotoNext, this._autoTime);
            }
        }
    }

    private becameNotVisible():void {
        if(this._isInView === false) {
            return;
        }
        this._isInView = false;

        this.stopAutoPaganation();
    }

    protected sleep(): void {
        for (let i = 0; i < this._numOfSlides; i++) {
            this._slides[i].removeEventListener('click', this.onSlideClick);
        }

        if(this._renderItem !== null) {
            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }

        App.RESIZE.onResize.unsub(this.updateDragger);
        super.sleep();
    }

    private onSlideClick = (e: MouseEvent) => {
        this.gotoItem(e.currentTarget as HTMLElement);
    };

    public getCurrentIndex = () => {
        const total: number = this._itemWidth * (this._numOfSlides - 1);
        let index: number = Math.round((-this.getX() / total) * (this._numOfSlides - 1));

        if (index > this._numOfSlides - 1) {
            index = this._numOfSlides - 1;
        } else if (index < 0) {
            index = 0;
        }

        if(isNaN(index)) {
            index = 0;
        }

        return index;
    };

    public getCurrentSlide():HTMLElement {
        return this._slides[this.getCurrentIndex()];
    }

    public getNumOfSlides() {
        return this._numOfSlides;
    }

    public getRatio() {
        let ratio = -this.getX() / -this._totalWidth;
        if (ratio > 1) {
            ratio = 1;
        } else if (ratio < 0) {
            ratio = 0;
        }
        return ratio;
    }

    public gotoNext = () => {
        let nextIndex = this.getCurrentIndex() + 1;
        if (nextIndex > this._numOfSlides - 1) {
            nextIndex = 0;
        }
        this.gotoItem(this._slides[nextIndex]);
    };

    public gotoPrev = () => {
        let nextIndex = this.getCurrentIndex() - 1;
        if (nextIndex < 0) {
            nextIndex = this.getNumOfSlides() - 1;
        }
        this.gotoItem(this._slides[nextIndex]);
    };

    public offsetItems(x: number) {
        for (let i = 0; i < this._numOfSlides; i++) {
            TweenLite.set(this._slides[i], { x: x * i });
            this.offsetItem = x * i;
        }

        this.updateDragger();
    }

    protected onUpdate() {
        this._onUpdate.dispatch();
    }

    private onDragStart() {
        // console.log('CoreSlideshowModule.onDragStart();');

        this._hasInteracted = true;

        this.stopAutoPaganation();
    }

    private stopAutoPaganation():void {
        if(this._autoTimeInterval !== null) {
            clearInterval(this._autoTimeInterval);
            this._autoTimeInterval = null;
        }
    }

    public getX() {
    	if(!this._slideContainer || !this._slideContainer._gsTransform) {
    		return 0;
	    }
        return this._slideContainer._gsTransform.x;
    }

    public gotoIndex(index:number, speed:number = .8) {
        this.gotoItem(this._slides[index], speed);
    }

    public gotoItem(item: HTMLElement, speed:number = .8) {
        let offset = parseInt(window.getComputedStyle(this._slides[0]).marginRight) * 0.5;

        // console.log('offset : ' + offset);

        let scrollTo = this._itemWidth * - this._slides.indexOf(item);

        // console.log(item.style.transform);
        
        // if (this._slides[this._numOfSlides - 1] === item) {
        //     let offset = -parseInt(window.getComputedStyle(this._slides[0]).marginRight) * 0.5;
        //     scrollTo += this.offsetSize + offset;
        // }

        for( let i = 0; i < this._numOfSlides; i++ ) {
            if(this._slides[i] === item){
                break;
            }
            scrollTo += -this.offsetItem / (this._numOfSlides - 1);
        }

        TweenLite.to(this._slideContainer, speed, {
            x: scrollTo,
            onUpdate: () => {
                this.onUpdate();
            },
            ease: Expo.easeOut
        });
    }

    protected updateDragger = () => {
        if (this._numOfSlides <= 1) {
            return;
        }

        // console.log('window.getComputedStyle(this.getElement()).width ' + window.getComputedStyle(this.getElement()).width);

        // this.getElement().innerText = window.getComputedStyle(this.getElement()).width;

	    this._staticWrapper.requestRender(()=> {
            let marginRight = parseInt(window.getComputedStyle(this._slides[0]).marginRight);
            if(isNaN(marginRight)) {
                marginRight = 0;
            }
            this._itemWidth = this._slides[0].offsetWidth + marginRight;
	    });

        this.offsetSize = parseInt(window.getComputedStyle(this._slides[0]).marginRight) * 0.5;
        if(isNaN(this.offsetSize)) {
            this.offsetSize = 0;
        } 

        let offset = -this.offsetItem - this.offsetSize;

        let minW = -this._itemWidth * (this._numOfSlides - 1) + this.offsetSize + offset;

        // console.log('minW : ' + minW);
        
        this._totalWidth = -this._itemWidth * (this._numOfSlides - 1) + this.offsetSize + offset;

        // console.log("this.offsetSize : " + this.offsetSize);
        // console.log("offset : " + offset);
        // console.log("this._numOfSlides : " + this._numOfSlides);
        // console.log("this._itemWidth : " + this._itemWidth);
        // console.log("this._totalWidth : " + this._totalWidth);

	    TweenLite.set(this._slideContainer, {x:-this._itemWidth * this.getCurrentIndex()});

        this._dragger = Draggable.create(this._slideContainer, {
            type: 'x',
            edgeResistance: 0.85,
            bounds: {
                minX: minW,
                maxX: 0
            },
            trigger: this._slideTrigger,

            // drag position
            minDuration: .2,
            maxDuration: .5,
            dragResistance: -1,
            zIndexBoost: false,
            onDragStart: () => {
              this.onDragStart();
            },
            // onDragEnd: () => {
            //     console.log('drag end');
            // },
            onDrag: () => {
                this.onUpdate();
            },
            onThrowUpdate: () => {
                this.onUpdate();
            },
            snap: (endValue:number) => {
                // console.log('snap');
                return minW / (this._numOfSlides - 1) * Math.round(endValue / minW * (this._numOfSlides - 1));
            },
            cursor: 'inherit',
            lockAxis: true,
            throwProps: true
        });

        this.onUpdate();
    };
}
