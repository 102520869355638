//Import for Webpack
import './HideWhenNotInView.scss';

import Module from 'core/ts/system/Module';
import {RenderItem} from "core/ts/utils/RenderLoop";
import RenderLoop from "core/ts/utils/RenderLoop";

export default class HideWhenNotInView extends Module {
    private _renderItem:RenderItem = null;

    protected awake(): void {
        this._renderItem = RenderLoop.Instance.add(this.checkinView, 5);
    }

    protected sleep(): void {
        if(this._renderItem !== null) {
            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }
    }

    private checkinView = (deltaTime:number, totalTime:number) => {
        this.viewInfo.updateRect();
        if(this.viewInfo.isInView()){
            this.getElement().style.display = 'block';
        } else {
            this.getElement().style.display = 'none';
        }
    };
}