import './VarDump.scss';

import VarDumpArray from "./classes/VarDumpArray";
import Component from 'core/ts/system/Component';
import StaticTemplate from 'core/ts/system/static/StaticTemplate';

/**
 * @ignore
 */
export default class VarDump extends Component {

	private _rawText: string;
	private _mainSegment: VarDumpArray;
	private _json: Object;

    protected awake(): void {
        this.getElement().classList.add("ready");

		this._rawText = this.getElement().innerHTML;
		this._json = JSON.parse(this._rawText);

		this.createSegments();
		this.draw();
        this.dispatchSizeUpdate();
	}

    protected sleep(): void {
        this._mainSegment.onToggle.unsub( this.dispatchSizeUpdate );
    }

    private createSegments() {
		this._mainSegment = new VarDumpArray(this._json, "data", 0, "");
		this._mainSegment.onToggle.sub( this.dispatchSizeUpdate );
	}

    private dispatchSizeUpdate = () => {
    	const temp = this.getRoot().getComponentByType<StaticTemplate>( StaticTemplate );
    	if(temp) {
            temp.calculatePageHeight();
	    }
    };

	public draw() {
		this.getElement().innerHTML = "";
		this.getElement().appendChild(this._mainSegment.createElement());
	}
}