//Import for Webpack
import './Preloader.scss';
import Component from "core/ts/system/Component";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import Timeout = NodeJS.Timeout;
import Module from "core/ts/system/Module";
import { SignalDispatcher } from 'strongly-typed-events';


export default class Pixelate extends Module {
    private _canvas:HTMLCanvasElement;
    private _container:HTMLElement;

    private _img:HTMLImageElement;
    private _ctx:CanvasRenderingContext2D;

    private _blockSizeX:number = 1;
    private _blockSizeY:number = 1;

    private _renderItem:RenderItem = null;
    private _renderItemCheckInView:RenderItem = null;

    private _animateInDelay:Timeout = null;

    private _effectDone:boolean = false;

    private _onEffectDone = new SignalDispatcher();
    public get onEffectDone() {
        return this._onEffectDone.asEvent();
    }

    protected build(): void {
        this._img = this.getElement() as HTMLImageElement;

        this._container = document.createElement('div');
        this._container.style.position = 'absolute';
        this._container.style.top = '0px';
        this._img.parentNode.appendChild(this._container);

        // console.log('this._img : ' + this._img);

        // this._canvas = document.createElement('canvas');
        // this._canvas.style.position = 'absolute';
        // this._container.appendChild(this._canvas);
        // this._img.style.opacity = '0';
        //
        // this._ctx = this._canvas.getContext('2d');
        // this._ctx.imageSmoothingEnabled = false;
    }

    protected awake():void {
        super.awake();

        this._img.addEventListener('load', this.onImageLoaded);
    }

    protected sleep():void {
        this._img.removeEventListener('load', this.onImageLoaded);

        if(this._animateInDelay !== null) {
            clearTimeout(this._animateInDelay);
            this._animateInDelay = null;
        }

        if(this._renderItem !== null) {
            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }

        if(this._renderItemCheckInView !== null) {
            RenderLoop.Instance.remove(this._renderItemCheckInView);
            this._renderItemCheckInView = null;
        }
    }

    private onImageLoaded = () => {
        // console.log('Pixelate.onImageLoaded();');
        this._img.removeEventListener('load', this.onImageLoaded);

        // this._canvas.width = this._img.width;
        // this._canvas.height = this._img.height;
        // this._ctx.imageSmoothingEnabled = false;
        //
        // this.blockSizeX = 32;
        // this.blockSizeY = 128;
        // this.pixelate();

        this._img.style.opacity = '1';

        // this._renderItemCheckInView = RenderLoop.Instance.add(this.shouldAnimateIn, 8);
    };

    private shouldAnimateIn = (deltaTime:number, totalTime:number):void => {
        this.viewInfo.updateRect();

        if(this.viewInfo.isInView()) {
            this._img.style.opacity = '1';

            RenderLoop.Instance.remove(this._renderItemCheckInView);
            this._renderItemCheckInView = null;

            this._animateInDelay = setTimeout(this.startAnimation, Math.random() * 50);
        }
    };

    private startAnimation = ():void => {
        this._renderItem = RenderLoop.Instance.add(this.render, 8);
    };

    private render = (deltaTime:number, totalTime:number):void => {
        // console.log('Pixelate.render();');

        this._canvas.width = this._img.width;
        this._canvas.height = this._img.height;
        this._ctx.imageSmoothingEnabled = false;

        if(this.blockSizeX > 1 || this.blockSizeY > 1) {
            let newBlockSizeX = this.blockSizeX * .5;
            if(newBlockSizeX < 1) {
                newBlockSizeX = 1;
            }
            this.blockSizeX = newBlockSizeX;

            let newBlockSizeY = this.blockSizeY * .5;
            if(newBlockSizeY < 1) {
                newBlockSizeY = 1;
            }
            this.blockSizeX = newBlockSizeX;
            this.blockSizeY = newBlockSizeY;

            this.pixelate();
        } else {
            RenderLoop.Instance.remove(this._renderItem);
            this._canvas.style.display = 'none';

            this._onEffectDone.dispatch();
            this._effectDone = true;
        }
    }

    protected onComplete = () => {
        this._canvas.style.display = 'none';
    };

    private set blockSizeX(blockSizeX:number) {
        // console.log(blockSizeX);

        this._blockSizeX = blockSizeX;
    }

    private set blockSizeY(blockSizeY:number) {
        // console.log(blockSizeY);

        this._blockSizeY = blockSizeY;
    }

    private get blockSizeX():number {
        return this._blockSizeX;
    }

    private get blockSizeY():number {
        return this._blockSizeY;
    }

    private pixelate():void {
        let newImageWidth = Math.round(this._canvas.width / this.blockSizeX );
        let newImageHeight = Math.round(this._canvas.height / this.blockSizeY );

        // draw original image to the scaled size
        this._ctx.drawImage(this._img, 0, 0, newImageWidth, newImageHeight);

        // then draw that scaled image thumb back to fill canvas
        // As smoothing is off the result will be pixelated
        this._ctx.drawImage(this._canvas, 0, 0, newImageWidth, newImageHeight, 0, 0, this._canvas.width, this._canvas.height);
    }

    public isEffectDone():boolean {
        return this._effectDone;
    }
}