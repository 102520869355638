//Import for Webpack
import Module from 'core/ts/system/Module';

export default class RemoveIfEmpty extends Module {
    protected build(): void {
        super.build();

        if(this.getElement().innerHTML === '') {
            this.getElement().style.display = 'none';
        }
    }
}