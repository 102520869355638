//Import for Webpack
import './LineModule.scss';

import Module from 'core/ts/system/Module';

export default class LineModule extends Module {

    /**
    * Template and modules will never be added or build before all nested modules are loaded
    * If you need something loaded before this and all childmodules gets build() and added to the dom. This is where you do it.
    * Call onComplete() parameter when you are done loading. (Dont forget this, also make sure to listen for fail and call onComplete).
    *
    * --This will only ever get called once.
    */
    // protected load( onComplete:Function ): void {
    //      //Call onComplete when loading is done
    //      onComplete();
    // }

    /**
    * Called when this and all nested modules are loaded.
    * Use this function to setup and manipulate the dom.
    *
    * --This will only ever get called once.
    * --Element is not yet added to the DOM
    */
    protected build(): void {
        const element:Element | undefined = this.getElement().nextElementSibling;

        const l:number = element.classList.length;

        for(let i:number = 0; i < l; i += 1) {
            const item:string = element.classList[i];

            if(item === 'Module') {
                continue;
            }
    
            this.getElement().setAttribute('data-next-' + item.toLowerCase(), '');
        }
    }

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    //protected awake(): void {
    //}

    /**
    * Called when the component is about to leave the scene
    * Use this to stop any ongoing loadings.
    * So that new loading content doesnt get added during an animation out or other exit/leave/destroy operations are in progress.
    *
    * --This gets called before animating out of screen or destroy/kill commands
    * --Added new content (by async loading) while destroying could potentially create some errors.
    */
    //protected unload(): void {
    //}

    /**
    * Called right before element gets removed from the dom
    * Remove listeners and kill stuff
    */
    //protected sleep(): void {
    //}

}