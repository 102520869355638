//Import for Webpack
import './TextLayout1Module.scss';

import CaseHeroModule from "modules/CaseHeroModule/CaseHeroModule";
import DefaultTemplate from "templates/DefaultTemplate";
import ComponentNode from "core/ts/system/ComponentNode";
import Component from "core/ts/system/Component";
import CoreCollapseModule from "core/modules/CoreCollapseModule/CoreCollapseModule";

export default class TextLayout1Module extends CoreCollapseModule {
    protected build(): void {
        this.moveCaseInfo();
        super.build();
    }

    private moveCaseInfo(): void {
        console.log('TextLayout1Module.moveCaseInfo();');
        // console.log(this.getParent().getComponents());

        let page: DefaultTemplate = this.getParent().getComponentByType<DefaultTemplate>(DefaultTemplate);
        // console.log('page : ' + page);

        if(page == null) {
            return;
        }

        let module: CaseHeroModule = page.getComponentByType<CaseHeroModule>(CaseHeroModule);
        if(module == null) {
            return;
        }

        // console.log('module : ' + module);

        let originalInfo = this.qs('.info');
        let node = module.getElement().querySelector('.info').cloneNode(true) as HTMLElement;
        // console.log('node.innerHTML : ' + node.innerHTML);

        ComponentNode.replaceChild(node, originalInfo);
    }
}