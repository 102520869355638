//Import for Webpack
import './MainMenuButton.scss';

import Component from "core/ts/system/Component";
import App from "ts/app";
import Path from "core/ts/utils/Path";

export default class MainMenuButton extends Component {
    private _isSelected:boolean = false;
    private _thisPath:string;

    protected build(): void {
        this.getElement().style.textDecoration = 'none';
        this._thisPath = Path.getWellFormatedPath(this.getElement().getAttribute('href'));

        this.onNewTemplate();
    }

    protected awake(): void {
        // this.getElement().addEventListener('mouseover', this.onMouseOver);
        // this.getElement().addEventListener('mouseout', this.onMouseOut);

        App.CONTENT.onNewPath.sub(this.onNewTemplate);
    }

    protected sleep(): void {
        // this.getElement().removeEventListener('mouseover', this.onMouseOver);
        // this.getElement().removeEventListener('mouseout', this.onMouseOut);

        App.CONTENT.onNewPath.unsub(this.onNewTemplate);
    }

    private onNewTemplate = () => {
        // console.log('Path.getCurrentInternalPathAtLevel(0) : ' + Path.getCurrentInternalPathAtLevel(0));

        if(Path.getCurrentInternalPathAtLevel(0) === this._thisPath) {
            this._isSelected = true;
            this.onMouseOver();
        } else {
            this._isSelected = false;
            this.onMouseOut();
        }
    };

    private onMouseOver = () => {
        this.getElement().style.textDecoration = 'underline';
    };

    private onMouseOut = () => {
        if(!this._isSelected) {
            this.getElement().style.textDecoration = 'none';
        }
    };
}