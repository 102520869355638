//Import for Webpack
import './DoubleImageModule.scss';

import Module from 'core/ts/system/Module';
import Breakpoints from "core/ts/utils/breakpoints/Breakpoints";

export default class DoubleImageModule extends Module {
    private _container1:HTMLElement = null;
    private _container2:HTMLElement = null;

    protected build(): void {
        super.build();

        this._container1 = this.qs('.container-1');
        this._container2 = this.qs('.container-2');

        this.onNewBreakpoint();
    }

    protected awake(): void {
        super.awake();

        Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
    }

    private onNewBreakpoint = ():void => {

        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            if(this._container1.innerHTML === '') {
                this._container2.style.marginTop = '0px';
            }

            if(this._container2.innerHTML === '') {
                this._container1.style.marginTop = '0px';
            }

            if(this._container1.innerHTML === '') {
                this._container1.style.display = 'none';
            }

            if(this._container2.innerHTML === '') {
                this._container2.style.display = 'none';
            }
        } else {
            this._container1.style.marginTop = null;
            this._container2.style.marginTop = null;

            this._container1.style.display = 'block';
            this._container2.style.display = 'block';
        }
    };

    protected sleep(): void {
        Breakpoints.Instance.onNewBreakpoint.unsub(this.onNewBreakpoint);
    }
}