//Import for Webpack
import './CoreCollapseModule.scss';

import Module from 'core/ts/system/Module';

/**
 * Usage
 *
 * Add .collapse-item class to collapsable items
 *
 */

export default class CoreCollapseModule extends Module {
    private _collapseButton: HTMLElement;
    private _collapseItems: NodeList;

    private _collapseIcon: HTMLElement;
    private _expandIcon: HTMLElement;

    private _collapseText: HTMLElement;
    private _expandText: HTMLElement;

    private _isOpen: boolean = false;

    protected build(): void {
        this._collapseButton = this.qs('.collapse-button');
        this._collapseItems = this.qsAll('.collapse-item');

        this._collapseIcon = this.qs('.collapse-icon');
        this._expandIcon = this.qs('.expand-icon');

        this._collapseText = this.qs('.collapse-text');
        this._expandText = this.qs('.expand-text');

        this.showOrHide(false);

        // console.log('CoreCollapseModule.build();');
        // console.log('this._collapseItems.length : ' + this._collapseItems.length);
    }

    protected awake(): void {
        this._collapseButton.addEventListener('click', this.onCollapse);
    }

    protected sleep(): void {
        this._collapseButton.removeEventListener('click', this.onCollapse);
    }

    private onCollapse = () => {
        // console.log('CoreCollapseModule.onCollapse();');

        this._isOpen = !this._isOpen;

        // console.log('this._isOpen : ' + this._isOpen);

        this.showOrHide(this._isOpen);

        if(this._isOpen === true) {
            this._collapseIcon.style.display = 'block';
            this._expandIcon.style.display = 'none';

            if(this._collapseText) {
                this._collapseText.style.display = 'block';
            }
            if(this._expandText) {
                this._expandText.style.display = 'none';
            }
        } else {
            this._collapseIcon.style.display = 'none';
            this._expandIcon.style.display = 'block';

            if(this._collapseText) {
                this._collapseText.style.display = 'none';
            }
            if(this._expandText) {
                this._expandText.style.display = 'block';
            }
        }
    };

    private showOrHide(show:boolean) {
        this._collapseItems.forEach(el => {
            if(el instanceof HTMLElement) {
                if(show === true) {
                    el.classList.add('open');
                } else {
                    el.classList.remove('open')
                }
            }
        });
    }
}