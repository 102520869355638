//Import for Webpack
import './CaseHeroModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";

export default class CaseHeroModule extends Module {
    protected build(): void {
        super.build();

        let header = this.qs('.case-header');
        header.innerHTML = header.innerHTML.replace('®', '<span class="super">®</span>');
    }

    protected awake(): void {
        LinkHandler.Instance.addAllAtags(this.getElement());
    }

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }
}