import {TweenLite, Expo, Ease} from 'gsap';
import TemplateManager from "core/ts/system/TemplateManager";
import Template from "core/ts/system/Template";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";

export default class ScrollControllerNative {
    private _currentScroll = { x: 0, y: 0 };

    private _scrollData:any = [];

    private _fake: HTMLElement;
    private _wrapper: HTMLElement;
    private _content: HTMLElement;

    private _pageHeight:number = -1;
    private _template:Template = null;

    private _renderItem:RenderItem;

    private static _instance:ScrollControllerNative;
    public static get Instance():ScrollControllerNative {
        if(this._instance == null) {
            this._instance = new ScrollControllerNative();
        }
        return this._instance;
    }

    constructor() {
        history.scrollRestoration = 'manual';
        window.addEventListener('wheel', this.onScrollWheel);
    }

    private onScrollWheel = () => {
        TweenLite.killTweensOf(this._currentScroll);

        this._currentScroll.y = window.scrollY;

        this.saveScroll();
    };

    public scrollTo(x:number, y:number, speed: number = -1, ease: Ease = Expo.easeOut):void {
        TweenLite.killTweensOf(this._currentScroll);
        this._currentScroll.x = window.scrollX;
        this._currentScroll.y = window.scrollY;

        // console.log('ScrollControllerNative.scrollTo();');
        // console.log('x : ' + x);
        // console.log('y : ' + y);

        if(speed <= 0) {
            this._currentScroll.x = x;
            this._currentScroll.y = y;
            this.updateWindowScroll();
        } else {
            TweenLite.to(this._currentScroll, 1,
                {
                    x: x,
                    y: y,
                    roundProps: ['x', 'y'],
                    ease: ease,
                    onUpdate: this.updateWindowScroll
                }
                );
        }
    }


    public getCurrentY():number {
        return this._currentScroll.y;
    }

    private updateWindowScroll = () => {
        // console.log('ScrollControllerNative.updateWindowScroll();');
        // console.log('this._currentScroll.y : ' + this._currentScroll.y);
        window.scrollTo(0, this._currentScroll.y);

        this.saveScroll();
    };

    private saveScroll():void {
        let template:Template = TemplateManager.Instance.getCurrentTemplate();
        // console.log('template.path : ' + template.path);

        this._scrollData[template.path] = this._currentScroll.y;
    }

    public restoreScroll():void {
        // console.log('ScrollControllerNative.restoreScroll();');

        let template:Template = TemplateManager.Instance.getCurrentTemplate();

        // console.log('template.path : ' + template.path);

        let scrollY:number = this._scrollData[template.path];

        // console.log('scrollY : '+ scrollY);

        this.scrollTo(0, scrollY);
    }
}