import Component from 'core/ts/system/Component';
import ResizeManager from 'core/ts/utils/ResizeManager';
import StaticElement from 'core/ts/system/static/StaticElement';
import {StaticWrapper} from 'core/ts/system/static/StaticWrapper';
import ColorManager from "ts/ColorManager";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";

export default class CrazyLines extends Component {
	private _canvas:HTMLCanvasElement;
	private _ctx:CanvasRenderingContext2D;

	private _lines:CrazyLine[] = [];

	private _width:number = 0;
	private _height:number = 0;

	private _numOfLines:number = 0;
	private _lastMouseX:number = Number.MAX_VALUE;

	private _staticWrapper:StaticWrapper;

	private _renderItem:RenderItem = null;

	private _sampleAmount:number = 1;

	protected build(): void {
		if( this.getElement() instanceof HTMLCanvasElement ) {
			this._canvas = this.getElement() as HTMLCanvasElement;
		} else {
			this._canvas = this.getElement().querySelector('canvas') as HTMLCanvasElement;
			if(!this._canvas) {
				this._canvas = document.createElement('canvas');
				this.getElement().appendChild( this._canvas );
			}
		}

		this._ctx = this._canvas.getContext('2d');
		this._ctx.imageSmoothingQuality = 'low';
		this._ctx.imageSmoothingEnabled = false;

		this._staticWrapper = new StaticWrapper(this);
	}

	protected awake(): void {
		ResizeManager.Instance.onResize.sub( this.onResize );

		this.onResize();
	}

	protected sleep(): void {
		ResizeManager.Instance.onResize.unsub( this.onResize );

		this.stopRender();
	}

	public startRender = () => {
		if(this._renderItem !== null) {
			return;
		}
		this._renderItem = RenderLoop.Instance.add(this.render, 60);

		this.getElement().addEventListener('mousemove', this.onMouseMove);
		this.render(0, 0);
	};

	public stopRender = () => {
		if(this._renderItem !== null) {
			RenderLoop.Instance.remove(this._renderItem);
			this._renderItem = null;
		}

		this.getElement().removeEventListener('mousemove', this.onMouseMove);
	};

	public resetLinePositions() {
		for( let i = 0; i < this._numOfLines; i++ ) {
			this._lines[i].x = (i % 2) ? 7 : 1;
			this._lines[i].velocityX = 0;
		}

		this.render(0, 0);
	};

	private onResize = () => {
		const offsetTop = Number.parseFloat(window.getComputedStyle(this.getElement()).paddingTop);

		this._staticWrapper.requestRender(()=> {
			this._width = this.getElement().offsetWidth;
			this._height = this.getElement().offsetHeight - offsetTop;
		});

		// console.log('this._width : ' + this._width);

		this._canvas.width = this._width * this._sampleAmount;
		this._canvas.height = this._height * this._sampleAmount;

		this._canvas.style.width = this._width + 'px';
		this._canvas.style.height = this._height + 'px';

		this.updateLineCount();
	};

	private onMouseMove = (e:MouseEvent) => {
		const mouseX = e.offsetX;
		const mouseY = e.offsetY;

		let deltaX = 0;
		if(this._lastMouseX != Number.MAX_VALUE){
			deltaX = mouseX - this._lastMouseX;
		}

		const ratioY = mouseY / this._height;
		let index = Math.floor(this._numOfLines * ratioY);
		if(index < 0){
			index = 0;
		} else if(index > this._numOfLines - 1) {
			index = this._numOfLines - 1;
		}

		const line = this._lines[index];
		if( deltaX < 0 ) {
			const vel = deltaX * 0.1;
			if( vel < line.velocityX ) {
				line.velocityX = vel;
			}
		} else if( deltaX > 0 ) {
			const vel = deltaX * 0.1;
			if( vel > line.velocityX ) {
				line.velocityX = vel;
			}
		}

		this._lastMouseX = mouseX;
	};

	private updateLineCount() {
		this._numOfLines = Math.ceil(this._height / 90);
		let i = this._numOfLines;

		if(this._numOfLines < 5) {
			this._numOfLines = 5;
		} else if( this._numOfLines > 16 ) {
			this._numOfLines = 16;
		}

		while( this._lines.length > this._numOfLines ) {
			this._lines.pop();
		}

		while( this._lines.length < this._numOfLines ) {
			i++;

			const line = new CrazyLine(this._ctx);
			line.x = (i % 2) ? 8 : 0;
			line.setSize( this._width, this._height / this._numOfLines );

			this._lines.push( line );
		}

		this.render(0, 0);
	};

	private render = (deltaTime:number, totalTime:number) => {
		this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
		// this._ctx.strokeStyle = 'rgb(0, 0, 0)';
		this._ctx.strokeStyle = ColorManager.Instance.foregroundColor;

		const singleHeight = (this._height / this._numOfLines) * this._sampleAmount;

		for( let i = 0; i < this._numOfLines; i++ ) {
			this._lines[i].y = singleHeight * i;
			// this._lines[i].thickness = 6;
			this._lines[i].thickness = 2 + i / this._numOfLines * 10;
			this._lines[i].setSize( this._width * this._sampleAmount, singleHeight );
			this._lines[i].render();
		}
	}

}


class CrazyLine {

	public spacing:number = 17;
	public thickness:number = 2;

	public x:number = 0;
	public y:number = 0;

	public drag:number = 0.98;
	public velocityX:number = 0;

	private _width:number = 0;
	private _height:number = 0;

	private _context:CanvasRenderingContext2D;

	constructor( ctx:CanvasRenderingContext2D ) {
		this._context = ctx;
	}

	public setSize(width:number, height:number ) {
		this._width = width;
		this._height = height;
	}

	public render() {
		const singleWidth = this.spacing;// + _instance.thickness;
		const numOfLines = Math.ceil(this._width / singleWidth) + 1;

		this.x += this.velocityX;
		this.velocityX = this.velocityX * this.drag;

		while( this.x < 0 ) {
			this.x += this._width;
		}

		let xPos = this.x;

		for( let i = 0; i < numOfLines; i++ ) {
			const loopX = xPos % (singleWidth * numOfLines) - this.thickness * 0.5;

			this._context.lineWidth = this.thickness;
			this._context.beginPath();
			this._context.moveTo(loopX, this.y);
			this._context.lineTo(loopX, this.y + this._height);
			this._context.closePath();
			this._context.stroke();

			xPos += singleWidth;
		}
	}
}