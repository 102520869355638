//Import for Webpack
import './ImageModule.scss';

import Module from 'core/ts/system/Module';

export default class ImageModule extends Module {
    // protected awake(): void {
    //     console.log("ImageModule.awake();");
    //     console.log("this.getElement().offsetWidth : " + this.getElement().offsetWidth);
    // }
}