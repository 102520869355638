import Component from "core/ts/system/Component";
import ColorManager from "ts/ColorManager";
import StringUtils from "core/ts/utils/StringUtils";
import LinkHandler from "core/ts/utils/LinkHandler";

export default class LinkHit extends Component {
    private _linkTarget:HTMLElement = null;
    private _href:string = null;

    protected awake(): void {
        super.awake();

        this.findTarget();

        if(this._linkTarget !== null) {
            this.getElement().addEventListener('click', this.onClick);
            // this.getElement().style.cursor = 'pointer';
        }
    }

    private findTarget():void {
        let target:HTMLElement = this.getElement();

        let linkPath:string = 'a';

        if(this.params.plain.linkpath) {
            linkPath = this.params.plain.linkpath;
        }

        let path:Array<string> = linkPath.split('/');

        // console.log('path : ' + path);

        while(path.length > 0) {
            if(path[0] === '..') {
                target = target.parentElement;
            } else {
                target = target.querySelector(path[0]);
            }

            path.splice(0, 1);
        }

        this._linkTarget = target;
    }

    private getHref():string {



        if(this._linkTarget instanceof HTMLAnchorElement) {
            return (this._linkTarget as HTMLAnchorElement).href;
        } else if(this._linkTarget instanceof  HTMLElement) {
            return this._linkTarget.innerText;
        }

        return null;
    }

    protected sleep(): void {
        super.sleep();

        this.getElement().removeEventListener('click', this.onClick);
    }

    private onClick = () => {
    	// console.log(this.getHref());
        LinkHandler.Instance.handleLinkClick(this.getHref());
    };
}