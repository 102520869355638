//Import for Webpack
import './FormingEssentialsModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from 'core/ts/utils/LinkHandler';
import ITransition from 'core/ts/system/transition/ITransition';
import { Transition } from 'core/ts/system/transition/TransitionController';
import Component from 'core/ts/system/Component';
import ScrollControllerNative from 'core/ts/utils/ScrollControllerNative';

export default class FormingEssentialsModule extends Module {
	protected awake(): void {
		LinkHandler.Instance.addAllAtags(this.getElement());
	}

	protected sleep(): void {
		LinkHandler.Instance.removeAllAtags(this.getElement());
	}
}
