import { Modules } from 'modules/AllModules';
import { SignalDispatcher } from 'strongly-typed-events';
import { DOMParameters } from 'core/ts/utils/DOMParameters';
import BoundingBox from 'core/ts/utils/BoundingBox';
import ComponentNode from "core/ts/system/ComponentNode";

/***********************************
 *
 * ```
 * Component.create( document.getElementById( 'MainMenu' ) );
 * ```
 *
 * A Component is always either:
 * - loaded or unloaded
 * - build or destroyed
 * - awake or sleeping
 *
 * All functions below are Magic functions and optional.
 *
 * <H4>On Create:</H4>
 *
 * - [[load]]
 *
 * - [[build]]
 *
 * - [[awake]]
 *
 * - (transitionIn if [[ITransition]] is implemented)
 *
 * <H4>On Delete:</H4>
 *
 * - [[unload]]
 *
 * - (transitionOut if [[ITransition]] is implemented)
 *
 * - [[sleep]]
 *
 * - [[destroy]]
 *
 *
 * It is possible to remove a [[ComponentNode]] and it's [[Component]]'s from the DOM without destroying it. In this case only [[sleep]] gets called.
 *
 * See more about removing elements her: [[ComponentNode.removeChild]]
 *
 *
 *
 ***********************************/
export default abstract class Component {
	/**********************************
	 * ------ MAGIC FUNCTIONS ------- *
	 **********************************/

	/**
	 * @Ignore
	 */
	public __componentTypeGuard: string = 'COMPONENT';

	private static ALL_ACTIVE_COMPONENTS:Component[] = [];

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Templates, Modules and Components will never be added or build before all nested modules are loaded.
	 *
	 * If you need something loaded before this and all child modules gets build() and added to the dom. This is where you do it.
	 *
	 * Call onComplete() parameter when you are done loading. (Dont forget this, also make sure to listen for fail and call onComplete).
	 *
	 * ```
	 * protected load(onComplete: Function): void {
	 *      this._img.onLoadingDone( ()=> {
	 *	        onComplete();
	 *      } );
	 *      this._img.onLoadingError( ()=> {
	 *	        onComplete();
	 *      } );
	 * }
	 * ```
	 *
	 * @param onComplete Call this Function when loading is done;
	 */
	protected load(onComplete: Function): void {
		onComplete();
	}

	/**
	 * @event onLoaded
	 */
	public get onLoad() {
		return this._onLoad.asEvent();
	}

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Called when this and all nested modules are loaded.
	 * Use this function to setup and manipulate the dom.
	 *
	 * --This will only ever get called once.
	 * --Element is not yet added to the DOM
	 */
	protected build(): void {}
	/**
	 * @event
	 */
	public get onBuild() {
		return this._onBuild.asEvent();
	}

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Called right after the element is added to the DOM.
	 * Add eventlisteners and prepare the component.
	 */
	protected awake(): void {}
	/**
	 * @event
	 */
	public get onAwake() {
		return this._onAwake.asEvent();
	}

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Called when the component knows it's about to leave the scene
	 * Use this to stop any ongoing loads.
	 * So that new loading content doesnt get added during an animation out or other exit/leave/destroy operations are in progress.
	 *
	 * --This gets called before animating out of screen or destroy/destroy commands
	 * --Added new content (by async loading) while destroying could potentially create some errors.
	 */
	protected unload(): void {}
	/**
	 * @event
	 */
	public get onUnload() {
		return this._onUnload.asEvent();
	}

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Called right before element gets removed from the dom
	 * Remove listeners
	 */
	protected sleep(): void {}
	/**
	 * @event
	 */
	public get onSleep() {
		return this._onSleep.asEvent();
	}

	/**
	 * <H4>MAGIC FUNCTION</H4>
	 *
	 * Called when a component gets destroyed.
	 *
	 * [[sleep]] and [[unload]] has also been called and when a component gets destroy it will never go [[awake]] again.
	 *
	 * Only a new instance with [[load]] and [[build]], will get this Component up an running again.
	 */
	protected destroy(): void {}
	/**
	 * @event
	 */
	public get onDestroy() {
		return this._onDestroy.asEvent();
	}

	/**********
	 * EVENTS *
	 **********/
	private _onLoad = new SignalDispatcher();
	private _onBuild = new SignalDispatcher();
	private _onAwake = new SignalDispatcher();
	private _onUnload = new SignalDispatcher();
	private _onSleep = new SignalDispatcher();
	private _onDestroy = new SignalDispatcher();

	/**
	 * parsed data parameters to the element, have a look at [[DOMParameters]].
	 **/
	public params: DOMParameters;

	/**
	 * Used when searching for child Components;
	 **/
	// protected searchTypes: string[] = ['any'];

    private readonly _node: ComponentNode = null;

	private _parentElement: Element;

	private _childComponentsLoaded = 0;

	private _isBuild: boolean = false;
	private _isAwake: boolean = false;
	private _isLoaded: boolean = false;
    private _isCompLoading:boolean = false;
	private _isDestroyed: boolean = false;

	/**
	 * @ignore
	 */
	public __autoAwake:boolean = false;
	// public cacheRootReference:boolean = true;

	/******************
	 * INTERNAL SETUP *
	 ******************/

	/**
	 * @ignore
	 */
	protected constructor(node: ComponentNode, parentElement: Element = null, autoAwake:boolean = false ) {
	    this._node = node;
		this._parentElement = parentElement;
		this.__autoAwake = autoAwake;
	}

	/**
	 * @ignore
	 *
	 * Gets called right after the constructor.
	 */
	public __init() {
		Component.ALL_ACTIVE_COMPONENTS.push( this );
        if(this.node.isInitialized()){
            this.setup();
        } else {
            this.node.onInitialized.sub( this.setup );
        }
	}

	private setup = () => {
	    // console.log()
        this.node.onInitialized.unsub( this.setup );
        this.node.onElementRemove.sub(this.__kill);
		this.node.onDestroy.sub(this.__kill);

        //Listen for new components and add excisting once;
        this.node.onChildAdded.sub( this.childComponentAdded );
        this.node.onSiblingAdded.sub( this.siblingAdded );

        this.node.getChildComponents().forEach(item=>{
           this.childComponentAdded(item);
        });
        this.node.getComponents().forEach(item=>{
            this.siblingAdded(item);
        });

        this.params = this.node.params;
    };


	/**
	 * The [[ComponentNode]] instance assigned to this Component and wrapped around the same Element [[getElement]].
	 */
	public get node():ComponentNode {
	    return this._node;
    }

	/**
	 * CREATING AND REMOVING
	 */

	/**
	 * Remove the component and element from the DOM
	 *
	 * Have a closer look at [[ComponentNode.removeChild]]
	 *
	 * @param destroy
	 */
	public removeThis( destroy:boolean = true ) {
	    this.node.removeThis(destroy);
	}


    private siblingAdded = ( comp:Component ) => {
        if( comp === this ) {
            return;
        }
        this.childComponentAdded( comp );
    };

    private childComponentAdded = ( comp:Component ) => {
        comp.onLoad.one(this.onChildComponentLoaded);
        comp.onBuild.one( this.onChildCompBuild );

        if (this._isLoaded) {
            comp.__tryLoad();
        }
    };

    private onChildCompBuild = ():void => {
		if(this.__autoAwake) {
    	    this.__tryAwake()
		}
    };

	private onChildComponentLoaded = (): void => {
		this._childComponentsLoaded++;
        this.__tryBuild();
	};

	private killAllEvents() {
		this.onLoad.clear();
		this.onBuild.clear();
		this.onAwake.clear();
		this.onUnload.clear();
		this.onSleep.clear();
	}

	/*********************************
	 * INTERNAL MAGIC FUNCTION LOGIC *
	 *********************************/

	/**
	 * @ignore
	 */
	public __kill = () => {
		if( this.node.isDestroyed() ) {
			this.__tryUnload();
		}
		this.__trySleep();
		if( this.node.isDestroyed() ) {
			this.__tryDestroy();
		}
	};

    private isAllChildrenAndSiblingsBuild() {
        const kids = this.node.getChildComponents( true );
        const l = kids.length;
        for (let i = 0; i < l; i++) {
            if(!kids[i].isBuild()){
                return false;
            }
        }
        return true;
    }

	private isAllChildrenAndSiblingsLoaded() {
		const kids = this.node.getChildComponents( true );
		const l = kids.length;
		for (let i = 0; i < l; i++) {
			if(!kids[i].isLoaded()){
				return false;
			}
		}
		return true;
	}

	/**
	 * @ignore
	 */
	protected __tryLoad(): void {
		if (this._isDestroyed || this._isLoaded || this._isCompLoading) {
			return;
		}
        this._isCompLoading = true;

		if (this.load.length <= 0) {
			console.error('The implemented Magic function "load()" is missing a onComplete::Function parameter.');
		}
        this.node.getChildComponents(true).forEach(c => c.__tryLoad());
		this.load(() => {
			this._isLoaded = true;
            this._isCompLoading = false;
            this.__tryBuild();
			this._onLoad.dispatch();
		});
	}

	/**
	 * @ignore
	 */
	private __tryBuild(): void {
		if (this._isBuild || this._isDestroyed || !this.isAllChildrenAndSiblingsLoaded() ) {
			return;
		}
        this.__build();
	}

	/**
	 * @ignore
	 */
	protected __build() {
        this._isBuild = true;
        this.build();

        this._onBuild.dispatch();

        if (this.node.hasParentComponentNode()) {
            let allAwake = true;
            this.node.getParent().getComponents().forEach(item => {
                 if( !item.isAwake() ) {
                     allAwake = false
                 }
            });

            if (allAwake) {
                this.__tryAwake();
            }

        } else {
            if (this._parentElement !== null && this._parentElement !== this.getElement().parentElement) {
                this._parentElement.appendChild(this.getElement());
            } else {
                this._parentElement = this.getElement().parentElement;
            }

            if (this.__autoAwake) {
                this.__tryAwake();
            }
        }

    }

	/**
	 * @ignore
	 */
	public __tryAwake() {
		if (this._isDestroyed || this._isAwake || !this.isAllChildrenAndSiblingsBuild() ) {
			return;
		}
        this.node.getChildComponents().forEach(item=> {
            item.__tryAwake();
        });
        this.__awake();

	}

    /**
     * @ignore
     */
	protected __awake() {
		this._isAwake = true;
		this.awake();
		this._onAwake.dispatch();
    }

	/**
	 * @ignore
	 */
	public __tryUnload(): void {
	    if(!this._isLoaded) {
	        return;
        }
	    this.__unload();
	}

    /**
     * @ignore
     */
	protected __unload() {
		this.node.getChildComponents().forEach(c => c.__tryUnload());
        this.node.onChildAdded.unsub( this.childComponentAdded );
        this.node.onSiblingAdded.unsub( this.siblingAdded );
		this._isLoaded = false;
		this.unload();
		this._onUnload.dispatch();
    }

	/**
	 * @ignore
	 */
	protected __trySleep(): void {
	    if(!this._isAwake) {
	        return;
        }
		this.__sleep();
	}


	/**
	 * @ignore
	 */
	protected __tryDestroy():void {
		if(!this._isBuild) {
			return;
		}

		this.__destroy();
	}

	/**
	 * @ignore
	 */
	protected __destroy() {
		this.node.getChildComponents().forEach(c => c.__tryDestroy());

		this._isDestroyed = true;
		this.node.onElementRemove.unsub(this.__kill);
		this.node.onDestroy.unsub( this.__kill);

		this.destroy();

		this._onDestroy.dispatch();

		const index = Component.ALL_ACTIVE_COMPONENTS.indexOf( this);
		if(index !== -1) {
			Component.ALL_ACTIVE_COMPONENTS.splice(index, 1);
		}
	}
    /**
     * @ignore
     */
	protected __sleep() {
        this.node.getChildComponents().forEach(c => c.__trySleep());

        this._isAwake = false;
        this.sleep();

        this._onSleep.dispatch();

        this.killAllEvents();
    }

	/********
	 * INFO *
	 ********/

	/**
	 * @return true if the Component is loaded
	 * Check [[load]] and [[unload]]
	 */
    public isLoaded(): boolean {
        return this._isLoaded;
    }
	/**
	 * @return true if the Component is build
	 * Check [[build]] and [[destroy]]
	 */
    public isBuild(): boolean {
		return this._isBuild;
	}

	/**
	 * @return true if the Component is awake
	 * Check [[awake]] and [[sleep]]
	 */
	public isAwake(): boolean {
		return this._isAwake;
	}

	/**
	 * @return true if the Component is awake
	 * Check [[destroy]]
	 */
	public isDestroyed():boolean {
    	return this._isDestroyed;
	}

	/**
	 * @returns The element this Component is attached to.
	 *
	 * <H4>HTML</H4>
	 *
	 * ```
	 * <div data-module="comp"></div>
	 * ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 * ```
	 * console.log( comp.getElement() ); //<div data-module="comp"></div>
	 * ```
	 *
	 */
	public getElement(): HTMLElement {
		return this.node.getElement();
	}

	/*****************************
	 * --------- SEARCH -------- *
	 *****************************/

    /**
     * @returns the parent node.
     * Notice in the example below that ParentElement and ParentNode is not the same thing.
     * compFour's parent node is still compThree even though they are nested within other elements.
     *
     * <H4>HTML</H4>
     *
     * ```
     * <div data-module="compOne">
     *     <div data-module="compTwo"></div>
     * </div>
     *
     * <div data-module="compThree">
     *      <div>
     *          <div data-module="compFour"></div>
     *      </div>
     * </div>
     * ```
     *
     * <H4>TypeScript</H4>
     *
     * ```
     * const nodeOne = compTwo.getParentNode();
     * const nodeTwo = compFour.getParentNode();
     * console.log( node.getSiblings()[0] ); // compOne
     * console.log( nodeTwo.getSiblings()[0] ); // compThree
     * ```
     *
     */
	public getParent(): ComponentNode {
		return this.node.getParent();
	}


	/**
	 * @returns the top most [[ComponentNode]] often used to get the node containing the [[Template]] Component.
	 *
	 * <H4>HTML</H4>
	 *
	 *  ```
	 *  <body>
	 *      <div data-module="AComponent"> {#This is root#}
	 *	        <div data-module="BComponent">
	 *              <div data-module="CComponent">
	 *			    </div>
	 *		    </div>
	 *      </div>
	 *      <div>
	 *          <div data-module="EComponent">
	 *              <div data-module="FComponent">
	 *			    </div>
	 *		    </div>
	 *      </div>
	 *
	 * </body>
	 *
	 *  ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 *  ```
	 *  console.log( CComponent.getRoot().getSiblings()[0] ); //AComponent
	 *  console.log( BComponent.getRoot().getSiblings()[0] ); //AComponent
	 *  console.log( FComponent.getRoot().getSiblings()[0] ); //EComponent
	 *  console.log( EComponent.getRoot().getSiblings()[0] ); //EComponent
	 *  ```
	 *
	 */
	public getRoot(): ComponentNode {
		return ComponentNode.searchRootComponentNode(this.node);
	}

	/**
	 * [[getType]], [[getTypes]] and [[getAllTypes]] is used to easily get references to child Components.
	 *
	 * <H4>HTML</H4>
	 *
	 * ```
	 * <div data-module="compOne, compTest">
	 *     <div data-module="compTwo"></div>
	 *     <div data-module="compThree">
	 *          <div data-module="compNested"></div>
	 *     </div>
	 *     <div data-module="compTwo"></div>
	 * </div>
	 * ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 * ```
	 * const comp = compOne.getAllTypes();
	 * console.log( comp ); // [compTwo, compThree, compTwo]
	 *
	 * const comp = compOne.getAllTypes( true );
	 * console.log( comp ); // [compTwo, compThree, compTwo, compTest]
	 *
	 * const comp = compOne.getAllTypes( true, true );
	 * console.log( comp ); // [compTwo, compThree, compTwo, compTest, compNested]
	 *
	 * const comp = compOne.getAllTypes( false, true );
	 * console.log( comp ); // [compTwo, compThree, compTwo, compNested]
	 *
	 * ```
	 *
	 * @param includeSiblings If set to true the return value will include siblings. Check [[getSiblings]] function to read more about siblings.
	 * @param recursive Keeps searching down the DOM for all Component children
	 */
    public getComponents(includeSiblings: boolean = false, recursive:boolean = false ): Component[] {
        const result = this.node.getChildComponents( includeSiblings );
        if(includeSiblings) {
            const index = result.indexOf( this );
            if(index !== -1) {
                result.splice(index, 1);
            }
        }

        if(recursive) {
            const l = result.length;
            for (let i = 0; i < l; i++) {
                if(result[i] === this) {
                    continue;
                }

                //Dont include siblings, Cuz the recursive parent already did
                const loopResult = result[i].getComponents(false, true);
                const loopL = loopResult.length;
                for (let j = 0; j < loopL; j++) {
                    if(result.indexOf(loopResult[j]) === -1) {
                        result.push(loopResult[j]);
                    }
                }
            }
        }

        return result;
    }

	/**
	 * [[getType]], [[getTypes]] and [[getAllTypes]] is used to easily get references to child Components.
	 *
	 * <H4>HTML</H4>
	 *
	 * ```
	 * <div data-module="compOne, compTest">
	 *     <div data-module="compTwo"></div>
	 *     <div data-module="compThree">
	 *          <div data-module="compNested">
	 *              <div data-module="compTest"></div>
	 *          </div>
	 *     </div>
	 *     <div>
	 *          <div data-module="compTwo"></div>
	 *     </div>
	 * </div>
	 * ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 * ```
	 * const comp:compTwo = compOne.getType<compTwo>(compTwo);
	 * //If multiple Component types are found it returns only the first one.
	 * console.log( comp ); //compTwo
	 *
	 * const comp:compNested = compOne.getType<compNested>(compNested, false, false);
	 * //compNested is nested under compThree so unless searchRecursive is true, nothing is found.
	 * console.log( comp ); // null
	 *
	 * const comp:compNested = compOne.getType<compNested>(compNested, false, true);
	 * console.log( comp ); // compNested
	 *
	 * const comp:compTest = compThree.getType<compTest>(compTest, false, true);
	 * console.log( comp ); // compTest
	 *
	 * const comp:compTest = compThree.getType<compTest>(compTest, false, true, [compNested]);
	 * //nested searching stop if compNested Component is found
	 * console.log( comp ); // null
	 *
	 * ```
	 *
	 *
	 *
	 * @param typef The Class Function used to instanceof checking. Should be same as the Generic T
	 * @param includeSiblings Whether or not siblings of the Component should be included in the search result.
	 * @param searchRecursive If set to false searching stop at any first child Component found.
	 * @param stopRecursiveIf A list if Classes that would stop the recursive search.
	 * This is typically used if you want to search recursively but only until the first child is found.
	 *
	 * ```
	 * compThree.getType<compTest>(compTest, false, true, [compTest]);
	 * ```
	 */
    public getComponentByType<T extends Component >(typef:Function, includeSiblings:boolean = true, searchRecursive:boolean = true, stopRecursiveIf:Function[] = [] ) : T {
        const components = this.node.getChildComponents(includeSiblings);
        const l = components.length;
        for (let i = 0; i < l; i++) {
            const item = components[i];
            if(item !== this && item instanceof typef){
                return item as T;
            }
        }

        if(searchRecursive) {
            for (let i = 0; i < l; i++) {
                const item = components[i];

                let breakRecursive:boolean = false;
                stopRecursiveIf.forEach(loopItem=> {
                   if(item instanceof loopItem) {
                       breakRecursive = true;
                   }
                });
                if(breakRecursive) {
                    continue;
                }

                if(item !== this ){
                    const result = item.getComponentByType<T>(typef, false, true );
                    if(result){
                        return result;
                    }
                }
            }
        }

        return null;
    }

	/**
	 * [[getType]], [[getTypes]] and [[getAllTypes]] is used to easily get references to child Components.
	 *
	 *
	 * <H4>HTML</H4>
	 *
	 * ```
	 * <div data-module="compOne, compTest">
	 *     <div data-module="compTwo"></div>
	 *     <div data-module="compThree">
	 *          <div data-module="compNested">
	 *              <div data-module="compTest"></div>
	 *          </div>
	 *     </div>
	 *     <div>
	 *          <div data-module="compTwo"></div>
	 *     </div>
	 * </div>
	 * ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 * ```
	 * const comp:compTwo = compOne.getTypes<compTwo>(compTwo);
	 * //If multiple Component types are found it returns only the first one.
	 * console.log( comp ); //[compTwo, compTwo]
	 *
	 * const comp:compNested = compOne.getTypes<compNested>(compNested, false, false);
	 * //compNested is nested under compThree so unless searchRecursive is true, nothing is found.
	 * console.log( comp ); // []
	 *
	 * const comp:compNested = compOne.getTypes<compNested>(compNested, false, true);
	 * console.log( comp ); // [compNested]
	 *
	 * const comp:compTest = compThree.getTypes<compTest>(compTest, false, true);
	 * console.log( comp ); // [compTest]
	 *
	 * const comp:compTest = compThree.getTypes<compTest>(compTest, false, true, [compNested]);
	 * //nested searching stop if compNested Component is found
	 * console.log( comp ); // []
	 *
	 * ```
	 *
	 * @param typef The Class Function used to instanceof checking. Should be same as the Generic T
	 * @param includeSiblings Whether or not siblings of the Component should be included in the search result.
	 * @param searchRecursive If set to false searching stop at any first child Component found.
	 * @param stopRecursiveIf A list if Classes that would stop the recursive search.
	 * This is typically used if you want to search recursively but only until the first child is found.
	 *
	 * ```
	 * //This would return all the first nested found MyClass Children instances.
	 * compThree.getType<MyClass>(MyClass, false, true, [MyClass]);
	 * ```
	 */
    public getComponentsByType<T extends Component >(typef:Function, includeSiblings:boolean = true, searchRecursive:boolean = true, stopRecursiveIf:Function[] = [] ) : T[] {
        let result:T[] = [];
        let components = this.node.getChildComponents(includeSiblings);
        let l = components.length;
        for (let i = 0; i < l; i++) {
            const item = components[i];
            if(item !== this && item instanceof typef){
                result.push(item as T);
            }
        }

        components = this.node.getChildComponents(false);
        l = components.length;
        if(searchRecursive) {
            for (let i = 0; i < l; i++) {
                const item = components[i];

                let breakRecursive:boolean = false;
                stopRecursiveIf.forEach(loopItem=> {
                    if(item instanceof loopItem) {
                        breakRecursive = true;
                    }
                });
                if(breakRecursive) {
                    continue;
                }

                const loopResult:T[] = item.getComponentsByType<T>(typef, true, true );
                const loopL = loopResult.length;
                for (let j = 0; j < loopL; j++) {
                    const resultItem = loopResult[j];
                    if(result.indexOf(resultItem) === -1) {
                        result.push(resultItem);
                    }
                }
            }
        }

        return result;
    }


	/*********
	 * UTILS *
	 *********/

    /**
     * @ignore
     */
	public toString(): string {
        let type = this.getElement().getAttribute(Component.SEARCH_MODULE);

        if(!type) {
            type = Component.__tagComponents[this.getElement().tagName.toUpperCase()];
        }
		return type;
	}

	/**
	 * @returns The elements BoundingClientRect but wrapped in a [[BoundingBox]] class.
	 *
	 * This is used in some extending cases to cache the bounding box for less browser redraw and performance boost.
	 *
	 * [[ViewInfo]] also uses this function instead of [[getElement]]().getBoundingClientRect(). So overriding this function, also let's you control the rect on which the calculations are made upon.
	 */
	public getBoundingClientRect(): BoundingBox {
		return BoundingBox.from(this.getElement().getBoundingClientRect());
	}

	private _qsMap: { [txt: string]: HTMLElement } = {};
	private _qsMapAll: { [txt: string]: NodeListOf<HTMLElement> } = {};

	/**
	 * Same as querySelector on [[getElement]], but with caching.
	 * This makes the searching quick but when changing the DOM, the caching remains.
	 *
	 * ```
	 * //Same as, but with caching
	 * this.getElement().querySelector(search);
	 * ```
	 *
	 * @param search the selector
	 * @param clearCache This clears the cache for the selector
	 */
	public qs(search: string, clearCache:boolean = false): HTMLElement {
		if (!clearCache && this._qsMap[search]) {
			return this._qsMap[search];
		}
		this._qsMap[search] = this.getElement().querySelector(search);
		return this._qsMap[search];
	}

	/**
	 * Same as querySelectorAll on [[getElement]], but with caching.
	 * This makes the searching quick but when changing the DOM, the caching remains.
	 *
	 * ```
	 * //Same as, but with caching
	 * this.getElement().querySelectorAll(search);
	 * ```
	 *
	 * @param search the selector
	 * @param clearCache This clears the cache for the selector
	 */
	public qsAll(search: string, clearCache:boolean = false): NodeListOf<HTMLElement> {
		if ( !clearCache && this._qsMapAll[search]) {
			return this._qsMapAll[search];
		}
		this._qsMapAll[search] = this.getElement().querySelectorAll(search);
		return this._qsMapAll[search];
	}


	/**************************************
	 * ------------  STATIC  ------------ *
	 **************************************/

	public static addComponent<T extends Component>( dom:Element, comp:string ):T {
		//TODO: THIS METHOD IS NOT YET TESTED;
		let currentModuleStr:string = dom.getAttribute('data-module');
		let newModuleString = currentModuleStr ? currentModuleStr + ", " + comp : comp;

		dom.setAttribute('data-module', newModuleString);

		// console.log(comp);

		// console.log(this.DOMComponents(dom));
		const node = Component.create(dom);
		const sibs = node.getComponents();

		for(let i = 0; i < sibs.length; i++) {
			if(sibs[i] instanceof Modules[comp]) {
				return sibs[i] as T;
			}
		}

		console.error('Yeee, this should never happen, Crap a hold of MC');
		return null;
	}

	/**
	 * The static readonly name of the attribute used by the system to define a [[ComponentNode]] and it's [[Component]]'s
	 */
	public static readonly SEARCH_MODULE: string = 'data-module';

	public static create(element: Element, parentNode: ComponentNode = null, autoAwake:boolean = true, ignore:Function[] = []): ComponentNode {
		const componentNames = Component.DOMComponents(element);
		const l = componentNames.length;

		/** If no components is found on the element, then loop through the dom and find the next modules **/
		if(l === 0) {
			Component.createSubComponents(element, parentNode, autoAwake, ignore);
			return;
		}

		/** Skip if element contains an ignore Function **/
		for (let i = 0; i < l; i++) {
			let templateName: string = componentNames[i];
			let ignoreTemplate:boolean = false;
			ignore.forEach( ignoreItem => {
				if( Modules[templateName] == ignoreItem ) {
					// console.log('IGNORE');
					ignoreTemplate = true;
				}
			});
			if(ignoreTemplate) {
				return;
			}
		}


		/** Look for existing Node or create a new one; **/
		let node = ComponentNode.searchActiveNodeFromElement( element );
		if(!node) {
			node = new ComponentNode( element, parentNode );
	        node.init();
		}


		const instances: Component[] = [];
		for (let i = 0; i < l; i++) {
			let templateName: string = componentNames[i];

			/** Search for existing component on element; **/
			const componentOnElement = Component.getActiveComponentFrom( element, Modules[templateName] );
			if(componentOnElement) {
				const index = node.getComponents().indexOf( componentOnElement );
				// console.warn( 'You are trying to create a new instance of a component that already exists on the element.' );
				if(index === -1) {
					//TODO: Implement default behavior;
					console.log( 'Element is not part of the Node.' );
				}
				continue;
			}

			/** Create Component **/
			let instance: Component;
			try {
				instance = new Modules[templateName](node);
			}catch(e) {
				console.warn("Could not find template '" + templateName + "' in AllModules.ts \n" +
					"on dom element:");
				console.warn(element);
			}

			if(!instance) {
				continue;
			}

			instance.__autoAwake = autoAwake;
            node.__addSibling(instance);
			instances.push(instance);
		}

		/** Main init all components **/
		instances.forEach( item => {
			item.__init();
		});

		/** Start Component Pipeline **/
		instances.forEach( item => {
			if (parentNode !== null) {
				parentNode.__addComponent(item);
			} else {
				item.__tryLoad();
			}
		});

		return node;
	}

	public static getActiveComponentsFrom(element:Element ):Component[] {
		const l = Component.ALL_ACTIVE_COMPONENTS.length;
		const result = [];

		for( let i = 0; i < l; i++ ) {
			let item = Component.ALL_ACTIVE_COMPONENTS[i];
			if( item.getElement() === element){
				result.push(item);
			}
		}
		return result;
	}

	public static getActiveComponentFrom(element:Element, classF:Function ):Component {
		const comps = Component.getActiveComponentsFrom( element );
		const l = comps.length;

		for( let i = 0; i < l; i++ ) {
			let item = comps[i];
			if( item.getElement() === element){
				if( item instanceof classF ) {
					return item;
				}
			}
		}
		return null;
	}

	private static DOMComponents(element: Element): string[] {
		const attr = element.getAttribute(Component.SEARCH_MODULE);
		let result: string[] = [];

		// console.log('attr : ' + attr);

		if (attr) {
			//replace spaces and split
			const replaced = attr.replace(/\s/g, ',').split(',');
			replaced.forEach( re => {
				if(re.length > 0) {
					result.push(re);
				}
			});
		}

		if (this.__tagComponents[element.tagName.toUpperCase()]) {
			result.push(this.__tagComponents[element.tagName.toUpperCase()]);
		}
		return result;
	}

	public static isComponent(el: any): el is Component {
		return (el as Component).__componentTypeGuard !== undefined;
	}

	public static createSubComponents(element: Element, parent: ComponentNode = null, autoAwake:boolean = false, ignore:Function[] = []): ComponentNode[] {
		const createdNodes:ComponentNode[] = [];
		const componentElements: Element[] = Component.getAllChildComponentDivs(element, true);
		for (let i = 0; i < componentElements.length; i++) {
			createdNodes.push(Component.create(componentElements[i], parent, autoAwake, ignore));
		}
		return createdNodes;
	}



	/**
	 *
	 * Only returns the first child component found and then stops looking further down the dom.
	 */
	public static getAllChildComponentDivs(element: Element, searchRecursive: boolean = true): Element[] {
		const tempData: HTMLCollection = element.children;
		if(!tempData) { return; }

		const l: number = tempData.length;
		const rVal: Element[] = [];
		for (let i = 0; i < l; i++) {
			const item: Element = tempData[i];
			if (Component.isElementOfComponentType(item)) {
				rVal.push(item);
			} else {
				/**
				 * SEARCH TEMPLATES RECURSIVELY
				 * This can be turned off if not used;
				 */
				if (!searchRecursive) { continue; }

				let extraItems = this.getAllChildComponentDivs(item);
				if(!extraItems) { continue; }

				let extraL = extraItems.length;
				for (let j = 0; j < extraL; j++) {
					if (extraItems[j] !== null) {
						rVal.push(extraItems[j]);
					}
				}

			}
		}
		return rVal;
	}

	public static isElementOfComponentType(element: Element) : boolean {
		return element.hasAttribute(Component.SEARCH_MODULE) || this.__tagComponents[element.tagName.toUpperCase()] !== undefined;
	}


	/**
	 * @ignore
	 */
	public static __tagComponents: { [txt: string]: string } = {};
	public static applyComponentToTag(tag: string, component: string) {
		this.__tagComponents[tag.toUpperCase()] = component;
	}


	/**
	 * @ignore
	 * Enabling JSX in typescript
	 * @params element
	 * @params properties
	 * @params children
	 * @constructor
	 */
	public static JSXCreate(element: string, properties: any, ...children: HTMLParagraphElement[]): Element {
		const el: any = document.createElement(element);
		Object.keys(this.nonNull(properties, {})).forEach(key => {
			el.setAttribute(key, properties[key]);
		});
		this.DOMparseChildren(children).forEach(child => {
			el.appendChild(child);
		});
		return el;
	}

	private static nonNull(val: any, fallback: any) {
		return Boolean(val) ? val : fallback;
	}

	private static DOMparseChildren(children: any[]) {
		return children.map(child => {
            if(typeof child === 'object') {
                return child;
            } else {
                return document.createTextNode(child.toString());
            }
		});
	}
}
