//Import for Webpack
import ResizeManager from 'core/ts/utils/ResizeManager';
import './MediaGrid.scss';

import Module from 'core/ts/system/Module';
import { Grid } from 'ts/grid/Grid';
import LazyLoadImage from 'core/ts/utils/image/LazyLoadImage';
import Breakpoints from 'core/ts/utils/breakpoints/Breakpoints';
import ComponentNode from 'core/ts/system/ComponentNode';
import Component from 'core/ts/system/Component';
import ImageGridSlideshow from 'modules/ImageGridSlideshow/ImageGridSlideshow';

export default class MediaGrid extends Module {
    private _grid: Grid = null;
	private _gridItems: NodeListOf<HTMLElement> = null;
	private _images:LazyLoadImage[] = null;
	private _gridContainer: HTMLElement = null;

	protected build(): void {
		
	}

	protected awake(): void {
		this._grid = new Grid();

		this._gridContainer = this.qs('.items');

        this._gridItems = this.qsAll('.item');

		this._gridItems.forEach((item: HTMLElement): void => {
			this._grid.addItem(item);

			item.addEventListener("click", this.onItemClick);
		});

        
        this._images = this.getComponentsByType(LazyLoadImage, false, true);
        for(var image of this._images) {
            image.onImgLoaded.sub(this.onImageLoaded);
        }

        // console.log("this._images.length : " + this._images.length);

		ResizeManager.Instance.onResize.sub(this.onResize);
        
		this.onResize();

	}

	private onItemClick = (e:MouseEvent):void => {
		// console.log("MediaGrid.onItemClick();");

		if(Breakpoints.Instance.getBreakpointName() === 'mobile') {
			return;
		}

		const target:HTMLElement = e.currentTarget as HTMLElement;
		const index:number = this.getIndex(target);

		if(index === - 1) {
			console.warn('index not found');
			return;
		}

		const templateData:HTMLElement = 
		<section 
			class="Module ImageGridSlideshow" 
			data-module="ImageGridSlideshow, ColorItemBackgroundBackground, CursorTarget"
			data-cursorName="default" data-sortIndex="1"
		>
			<div class="outer-container">
				<section class="container-with-padding top">
					<div class="font-style-6 column-1 title" data-module="ColorItemTextToForeground"></div>
					<div class="column-2">
						<p class="font-style-6 description" data-module="ColorItemTextToForeground"></p>
						<p class="font-style-6 platform" data-module="ColorItemTextToForeground"></p>
					</div>
					<div class="column-3 buttons">
						<a class="basic-button font-style-6 close-button" data-module="ColorItemTextToForeground">Close</a>
					</div>
					<div class="column-4">
						<div class="font-style-8 page-info" data-module="SlideshowPageInfo, ColorItemTextToForeground" data-format="[CURRENT].[TOTAL]"></div>
					</div>
				</section>

				<div class="mask trigger" data-module="CursorTarget" data-cursorName="slideshow" data-sortIndex="1">
					<div class="container">
						
					</div>
				</div>
			</div>
		</section>;

		templateData.setAttribute('data-start-index', index.toString());

		(templateData.querySelector(".title") as HTMLElement).innerText = this.params.plain.gridname;
		(templateData.querySelector(".description") as HTMLElement).innerText = this.params.plain.title;
		(templateData.querySelector(".platform") as HTMLElement).innerText = this.params.plain.category;

		const container:HTMLElement = templateData.querySelector('.container');
		
		this._gridItems.forEach((item: HTMLElement): void => {
			const originalImg = item.querySelector('img');
			const module:HTMLElement = item.querySelector('.Module');
			
			if( module.classList.contains("ImageModule")) {
				const imageModuleDOM:HTMLElement = module.cloneNode(true) as HTMLElement;

				const newImage = imageModuleDOM.querySelector('img');
				newImage.setAttribute('data-src', originalImg.getAttribute('data-src'));
				newImage.setAttribute('src', '');
				newImage.setAttribute('data-aspectratio', originalImg.getAttribute('data-aspectratio'));
				
				newImage.style.width = null;
				newImage.style.height = null;
				newImage.style.objectFit = null;

				container.appendChild(imageModuleDOM);
			} 
			else if(module.classList.contains("VideoModule")) {
				const videoModuleDOM:HTMLElement = module.cloneNode(true) as HTMLElement;
				
				// only used if Vimeo
				videoModuleDOM.setAttribute('data-autoplay', 'true');

				// only used if not Vimeo
				const video:HTMLVideoElement | undefined = videoModuleDOM.querySelector('video');
				if(video) {
					video.autoplay = true;
				}

				const newImage = videoModuleDOM.querySelector('img');
				newImage.setAttribute('data-src', originalImg.getAttribute('data-src'));
				newImage.setAttribute('src', '');
				newImage.setAttribute('data-aspectratio', originalImg.getAttribute('data-aspectratio'));

				newImage.style.width = null;
				newImage.style.height = null;
				newImage.style.objectFit = null;

				container.appendChild(videoModuleDOM);
			} else {
				console.log('Content not supported');
			}
		});

	
		ComponentNode.addChild(templateData, this.getParent());
	}

	private getIndex(target:HTMLElement):number {
		let index = 0;
		let targetIndex = - 1;
		this._gridItems.forEach((item) => {
			if(target === item) {
				targetIndex = index;
				return;
			}
			
			index += 1;
		});

		return targetIndex;
	}


	protected destroy(): void {
		super.destroy();

        for(var image of this._images) {
            image.onImgLoaded.unsub(this.onImageLoaded);
        }

		this._gridItems.forEach((item: HTMLElement): void => {
			item.removeEventListener("click", this.onItemClick);
		});

        ResizeManager.Instance.onResize.unsub(this.onResize);
	}

    private onImageLoaded = ():void => {
        this.onResize();
    }

	private onResize = (): void => {
		// console.log('this._container.offsetWidth ' + this._gridContainer.offsetWidth);
		
        let gutterSize:number = 15;
		// console.log('gutterSize : ' + gutterSize);
        
		this._grid.resize({
			width: this._gridContainer.offsetWidth, 
			numberOfColumns: Breakpoints.Instance.getBreakpointName() === 'mobile' ? 1 : 4, 
			gutter: gutterSize,
			closeSmallerGapsTreshold: Breakpoints.Instance.getBreakpointName() === 'mobile' ? undefined : .2,
			fixMisalignmentsTreshold: Breakpoints.Instance.getBreakpointName() === 'mobile' ? undefined : 15,
			// ySpacing: this._gridContainer.offsetWidth * .009,
			// closeSmallGapsIfOverlappingToTheLeft: true,
			// alignWithNewLine: true,
			
		});
		
		this._gridContainer.style.height = this._grid.getHeight() + 'px';
	};
}