import Component from "core/ts/system/Component";
import Cursor from "core/ts/utils/cursor/Cursor";
import { TweenLite, Expo } from 'gsap';
import ColorManager from "ts/ColorManager";

export default class EnlargeCursor extends Cursor {
	public pt1:any = {x: 50, y: 50};
	public pt2:any = {x: 50, y: 50};

	public pt3:any = {x: 50, y: 50};
	public pt4:any = {x: 50, y: 50};
	public pt5:any = {x: 50, y: 50};
	public pt6:any = {x: 50, y: 50};

	public pt7:any = {x: 50, y: 50};
	public pt8:any = {x: 50, y: 50};
	public pt9:any = {x: 50, y: 50};
	public pt10:any = {x: 50, y: 50};

	private _element:HTMLElement = document.createElement('div');

	public getElement():HTMLElement {
		this._element.style.position = 'absolute';
		this._element.style.left = '-50px';
		this._element.style.top = '-50px';
		this._element.classList.add('cursor');

		TweenLite.set(this._element, {rotation: -45});

		return this._element;
	}

	public activate(oldCursor:Cursor = null):void {
		TweenLite.killTweensOf([this.pt1, this.pt2, this.pt3, this.pt4, this.pt5, this.pt6, this.pt7, this.pt8, this.pt9, this.pt10]);

		this.pt1.x = 50;
		this.pt1.y = 50;
		this.pt2.x = 50;
		this.pt2.y = 50;

		const offsetY:number = .5;
		const offsetX:number = 0;
		const width:number = 52;
		const halfWidth:number = width / 2;
		const wingSize:number = 12;

		this.pt3.x = 50 - halfWidth + offsetX;
		this.pt3.y = 50 + offsetY;
		this.pt4.x = 50 + offsetX;
		this.pt4.y = 50 + offsetY;

		this.pt5.x = 50 - halfWidth + offsetX;
		this.pt5.y = 50 - offsetY;
		this.pt6.x = 50 + offsetX;
		this.pt6.y = 50 - offsetY;

		this.pt7.x = 50 + halfWidth + offsetX;
		this.pt7.y = 50 + offsetY;
		this.pt8.x = 50 + offsetX;
		this.pt8.y = 50 + offsetY;

		this.pt9.x = 50 + halfWidth + offsetX;
		this.pt9.y = 50 - offsetY;
		this.pt10.x = 50 + offsetX;
		this.pt10.y = 50 - offsetY;

		TweenLite.to(this.pt1, .4, {x: 50 - halfWidth, ease: Expo.easeOut });
		TweenLite.to(this.pt2, .4, {x: 50 + halfWidth, ease: Expo.easeOut });

		TweenLite.to(this.pt4, .4, {x: 50 - halfWidth + offsetX + wingSize, y: 50 + offsetY - wingSize, ease: Expo.easeOut, delay: .1 });
		TweenLite.to(this.pt6, .4, {x: 50 - halfWidth + offsetX + wingSize, y: 50 - offsetY + wingSize, ease: Expo.easeOut, delay: .1 });

		TweenLite.to(this.pt8, .4, {x: 50 + halfWidth + offsetX - wingSize, y: 50 + offsetY - wingSize, ease: Expo.easeOut, delay: .1 });
		TweenLite.to(this.pt10, .4, {x: 50 + halfWidth + offsetX - wingSize, y: 50 - offsetY + wingSize, ease: Expo.easeOut, delay: .1, onComplete: this.animationDone });

		this.startRender();
	}

	protected animationDone = () => {
		super.stopRender();
	};

	protected render(deltaTime:number, totalTime:number) {
		super.render(deltaTime, totalTime);

		let content:string = '<svg height="100" width="100">\' +\n' +
			'\t\t\t\'<line x1="' + this.pt1.x + '" y1="' + this.pt1.y + '" x2="' + this.pt2.x + '" y2="' + this.pt2.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt3.x + '" y1="' + this.pt3.y + '" x2="' + this.pt4.x + '" y2="' + this.pt4.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt5.x + '" y1="' + this.pt5.y + '" x2="' + this.pt6.x + '" y2="' + this.pt6.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt7.x + '" y1="' + this.pt7.y + '" x2="' + this.pt8.x + '" y2="' + this.pt8.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt9.x + '" y1="' + this.pt9.y + '" x2="' + this.pt10.x + '" y2="' + this.pt10.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\'</svg>';

		this._element.innerHTML = content;
	}
}