//Import for Webpack
import './MenuModule.scss';

import Path from 'core/ts/utils/Path';
import Module from "core/ts/system/Module";
import LinkHandler from "core/ts/utils/LinkHandler";
import App from "ts/app";

export default class MenuModule extends Module {
    private _toogleButton:HTMLElement;
    private _openIcon:HTMLElement;
    private _closeIcon:HTMLElement;
    private _content:HTMLElement;
    private _logoOpen:HTMLElement;
    private _logoClosed:HTMLElement;

    protected build(): void {
        this._toogleButton = this.qs('.open-menu-button');
        this._content = this.qs('.content');

        this._openIcon = this.qs('.open-icon');
        this._closeIcon = this.qs('.close-icon');

        this._logoOpen = this.qs('.logo-open');
        this._logoClosed = this.qs('.logo-closed');

        this._content.style.pointerEvents = 'none';

        // console.log(Path.getCurrentInternalPathAtLevel(0));
    }

    protected awake(): void {
        this._toogleButton.addEventListener('click', this.onToggleMenu);
        LinkHandler.Instance.addAllAtags(this.getElement());

        App.CONTENT.onNewPath.sub(this.onNewPath);
    }

    protected sleep(): void {
        this._toogleButton.removeEventListener('click', this.onToggleMenu);
        LinkHandler.Instance.removeAllAtags(this.getElement());

        App.CONTENT.onNewPath.unsub(this.onNewPath);
    }

    private onNewPath = () => {
        this.hideMenu();
    };

    private onToggleMenu = () => {
        // console.log('MenuModule.onToggleMenu();');

        if(window.getComputedStyle(this._content).display == 'none') {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    };

    private showMenu():void {
        this._content.style.display = 'block';
        this._openIcon.style.display = 'none';
        this._closeIcon.style.display = 'block';
        this._content.style.pointerEvents = 'auto';
        this._logoClosed.style.display = 'none';
        this._logoOpen.style.display = 'block';
    }

    private hideMenu():void {
        this._content.style.display = 'none';
        this._openIcon.style.display = 'block';
        this._closeIcon.style.display = 'none';
        this._content.style.pointerEvents = 'none';
        this._logoClosed.style.display = 'block';
        this._logoOpen.style.display = 'none';
    }
}