import {SignalDispatcher} from 'strongly-typed-events';
import Component from "core/ts/system/Component";
import {StaticWrapper} from 'core/ts/system/static/StaticWrapper';
import ResizeManager from 'core/ts/utils/ResizeManager';
import Breakpoints from "core/ts/utils/breakpoints/Breakpoints";

export default class LazyLoadImage extends Component {

	public static BASIC_LOAD_SIZES: number[] = [1, 25, 50, 100, 200, 400, 600, 800, 1200, 1500, 1800, 2600, 3600];

	private _onImgLoaded = new SignalDispatcher();
	public get onImgLoaded() {
		return this._onImgLoaded.asEvent();
	}

	private _origWidth: number = 0;
	public get origWidth(): number {
		return this._origWidth;
	}

	private _origHeight: number = 0;
	public get origHeight(): number {
		return this._origHeight;
	}

	private _isImgLoaded: boolean = false;
	private _isLoading: boolean = false;
	private _currentUrl: string = '';

	private _fakeLoader = new Image();
	private _static: StaticWrapper;

	private _lastLoadedSize:number = 0;


	/**
	 * System functions
	 */
	protected load(onComplete: Function): void {
		this._static = new StaticWrapper(this);

		if (this.params.plain.loadbeforeshow) {
			this.onImgLoaded.one(() => {
				onComplete();
			});

			this.loadAppropriateImage();
			return;
		} else {
			onComplete();
		}
	}

	protected awake(): void {
		// console.log('LazyLoadImage.awake();');

		this.getElement().style.opacity = '0';

		ResizeManager.Instance.onResize.sub(this.onResize);
		this.onResize();
	}

	protected sleep(): void {
		ResizeManager.Instance.onResize.unsub(this.onResize);

		this._fakeLoader.removeEventListener('load', this.onImageDataLoaded, true);
		this.getElement().removeEventListener('load', this.onImageLoadedAndAdded);
	}


	/**
	 * Resize
	 */
	protected onResize = () => {
		// this.getElement().style.width = this.getElement().parentElement.offsetWidth + 'px';
		const newHeight:number = this.getElement().parentElement.offsetWidth * this.getAspectRatio();
		// console.log('newHeight : ' + newHeight);
		this.getElement().style.height = newHeight + 'px';
		this.loadAppropriateImage();
	};

	protected getWidthToLoad(): number {
		return this.roundToLoadSize(this.getElement().offsetWidth) * this.getDevicePixelRatio();
	}

	protected getHeightToLoad(): number {
		return Math.round(this.getWidthToLoad() * this.getAspectRatio());
	}

	protected getAspectRatio(): number {
		let raw: string = this.params.plain.aspectratio ? this.params.plain.aspectratio : '1x1';
		let array: Array<string> = raw.split('x');
		return parseFloat(array[1]) / parseFloat(array[0]);
	}

	protected roundToLoadSize(size: number) {
		const l = LazyLoadImage.BASIC_LOAD_SIZES.length;
		/** Search for matching size **/
		for (let i = 0; i < l; i++) {
			const basicSize: number = LazyLoadImage.BASIC_LOAD_SIZES[i];
			/** find a size greater the needed and never less then already loaded **/
			if (basicSize > size && basicSize >= this._lastLoadedSize) {
				this._lastLoadedSize = basicSize;
				break;
			}
		}

		/** default to highest size **/
		if(this._lastLoadedSize === 0) {
			this._lastLoadedSize = LazyLoadImage.BASIC_LOAD_SIZES[l - 1];
		}
		return this._lastLoadedSize;
	}

	private getDevicePixelRatio(): number {
		let ratio: number = 1;
		if (window.devicePixelRatio) {
			ratio = window.devicePixelRatio;
		}
		return ratio;
	}


	/**
	 * Loading
	 */
	public isImgLoaded(): boolean {
		return !this._isLoading && this._isImgLoaded;
	}

	protected loadAppropriateImage = (): void => {
		if (this._isLoading) {
			return;
		}

		let newUrl = this.getLoadUrl();
		if (this._currentUrl === newUrl) {
			return;
		}

		// console.log('load : ' + newUrl);

		this._currentUrl = newUrl;

		this._isLoading = true;

		this._fakeLoader.addEventListener('load', this.onImageDataLoaded, true);

		// console.log('this._currentUrl : ' + this._currentUrl);
		this._fakeLoader.src = this._currentUrl;
	};

	protected getLoadUrl(): string {
		return this.params.plain.src;
	}

	private onImageDataLoaded = () => {
		this._fakeLoader.removeEventListener('load', this.onImageDataLoaded, true);

		this.getElement().addEventListener('load', this.onImageLoadedAndAdded);

		this.getElement().setAttribute('src', this._currentUrl);
	};

	private onImageLoadedAndAdded = () => {
		this.getElement().removeEventListener('load', this.onImageLoadedAndAdded);

		this._origWidth = this._fakeLoader.naturalWidth;
		this._origHeight = this._fakeLoader.naturalHeight;

		// Fixing css height 100% not updating on image load;
		this.getElement().parentNode.insertBefore(this.getElement(), this.getElement());

		this.getElement().style.opacity = 'inherit';

		this._isLoading = false;
		this._isImgLoaded = true;
		this._onImgLoaded.dispatch();
		this._static.calculatePageHeight();

		this.onResize();
	}
}