//Import for Webpack
import './FooterModule.scss';

import Module from 'core/ts/system/Module';
import CrazyLines from 'components/CrazyLines';
import {RenderItem} from "core/ts/utils/RenderLoop";
import RenderLoop from "core/ts/utils/RenderLoop";

export default class FooterModule extends Module {
	private _crazyLines:CrazyLines;

	private _renderItem:RenderItem = null;

	protected build(): void {
		this._crazyLines = this.getComponentByType<CrazyLines>(CrazyLines);
	}

	protected awake(): void {
		this._renderItem = RenderLoop.Instance.add(this.checkinView, 5);
	}

	protected sleep(): void {
		if(this._renderItem !== null) {
			RenderLoop.Instance.remove(this._renderItem);
			this._renderItem = null;
		}

		this._crazyLines.stopRender();
	}

	private checkinView = (deltaTime:number, totalTime:number) => {
		this.viewInfo.updateRect();
		if(this.viewInfo.isInView()){
			this._crazyLines.startRender();
		} else {
			this._crazyLines.stopRender();
		}
	};
}