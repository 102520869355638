import Component from "core/ts/system/Component";

export default class RenderLoop {
    private _lastRenderTime:number = 0;
    private _totalTime:number = 0;
    private _deltaTime:number = 0;

    private _renderItems:Array<RenderItem> = [];
    private _killItems:Array<RenderItem> = [];

    // private _debug:HTMLElement = null;

    private static _instance: RenderLoop = null;

    public static get Instance(): RenderLoop {
        if(this._instance === null) {
            this._instance = new RenderLoop();

            setInterval(():void => {
                this._instance.render()
            },  1000 / 60);
        }

        return this._instance;
    }

    public constructor() {
        // this._debug = document.createElement('div') as HTMLElement;
        // this._debug.style.zIndex = '1000000';
        // this._debug.style.position = 'fixed';
        // this._debug.style.color = 'green';
        // this._debug.style.left = '20px';
        // this._debug.style.top = '20px';
        // document.body.appendChild(this._debug);

        this._lastRenderTime = Date.now();
    }

    public add(renderFunction:(deltaTime: number, totalTime:number) => any, fps:number = 60):RenderItem {
        // console.log('RenderLoop.add();' + renderFunction.toString());

        let renderItem = new RenderItem(renderFunction, fps);
        this._renderItems.push(renderItem);
        return renderItem;
    }

    public remove(renderItem:RenderItem):void {
        // console.log('RenderLoop.remove();');

        renderItem.killed = true;
        this._killItems.push(renderItem);
    }

    private render = () => {
        let currentTime:number = Date.now();
        let timePassed = (currentTime - this._lastRenderTime) / 1000;

        // console.log('timePassed : ' + timePassed)

        if(timePassed <= 0) {
            console.log('Skip frame');
            // requestAnimationFrame( this.render );
            // return;
        }


        this._lastRenderTime = currentTime;
        this._totalTime += timePassed;
        this._deltaTime = timePassed;

        let l = this._renderItems.length;

        // let text:string = '';
        // text += 'RenderLoop - ' + timePassed + ' time\n';
        // text += 'items: ' + l + '\n';
        // this._debug.innerText = text;

        let item:RenderItem;

        // console.log('l : ' + l);

        for(let i = 0; i < l; i += 1) {
            item = this._renderItems[i];
            if(item.killed === true) {
                continue;
            }
            item.render(this._deltaTime);
        }

        // console.log('this._killItems.length : ' + this._killItems.length);

        while(this._killItems.length > 0) {
            let index = this._renderItems.indexOf(this._killItems[0]);
            // console.log('index : ' + index);
            if(index !== - 1) {
                this._renderItems.splice(index, 1);
            }
            this._killItems.shift();
        }

        // requestAnimationFrame( this.render );
    }
}

export class RenderItem {
    public totalTime:number  = 0;
    public deltaTime:number  = 0;
    public renderFunction:Function;
    public killed:boolean = false;
    private _fps:number;

    constructor(renderFunction:(deltaTime: number, totalTime:number) => any, fps:number) {
        this.renderFunction = renderFunction;
        this._fps = fps;
    }

    public render(deltaTime:number):void {
        this.totalTime += deltaTime;
        this.deltaTime += deltaTime;

        const secondsPerFrame:number = 1 / this._fps;
        if(this.deltaTime < secondsPerFrame) {
            return;
        }

        this.renderFunction(this.deltaTime, this.totalTime);
        // this.deltaTime = secondsPerFrame;
        this.deltaTime = 0;
    }
}