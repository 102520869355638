//Import for Webpack
import Module from 'core/ts/system/Module';
import RenderLoop, { RenderItem } from 'core/ts/utils/RenderLoop';
import { TweenLite, Expo, Sine, Linear, Ease } from 'gsap';
import Player from '@vimeo/player';
import Timeout = NodeJS.Timeout;

export default class VimeoComponent extends Module {
	private _playButton: HTMLElement;

	private _playIcon: HTMLElement;
	private _pauseIcon: HTMLElement;

	private _playbackTime: HTMLElement;
	private _placeholder: HTMLElement;
	private _video: Player = null;

	private _isPlaying: boolean = false;
	private _wasInteractedWith: boolean = false;

	private _renderItem: RenderItem = null;
	private _videoReadyForPlay: boolean = false;

	private _autoPlay: boolean = true;
	private _videoUI: string;

	private _videoContainer: HTMLElement;

	private _isVideoLoaded: boolean = false;
	private _restoreTime: number = 0;

	private _timeInView: number = 0;

	private _currentTime: number = 0;
	private _duration: number = 0;

	private _id: string;

	private _hideTimeout: Timeout = null;

	private _layer:string;

	private static _videoLayers:Map<string, boolean> = new Map();

	public static setLayerEnabled(id:string, enabled:boolean):void {
		this._videoLayers.set(id, enabled);
	}
	
	public static getLayerEnabled(id:string):boolean {
		return this._videoLayers.get(id) !== false;
	}

	protected build(): void {
		// console.log('VimeoComponent.build();');

		this._playButton = this.qs('.play-button');
		this._playIcon = this.qs('.play-icon');
		this._pauseIcon = this.qs('.pause-icon');
		this._placeholder = this.qs('.placeholder');
		this._playbackTime = this.qs('.playback-time');
		this._videoContainer = this.qs('.container');

		this._layer = this.params.plain.layer ? this.params.plain.layer : 'default';

		// console.log('this.params.plain.autoplay : ' + this.params.plain.autoplay);
		// console.log('this.params.plain.videoui : ' + this.params.plain.videoui);
		// console.log('this.params.plain.id : ' + this.params.plain.id);

		this._videoUI = this.params.plain.videoui;
		if (!this._videoUI) {
			this._videoUI = 'default';
		}

		this._autoPlay = this.params.plain.autoplay === 'true';
		// console.log('this._autoPlay : ' + this._autoPlay);
		this._id = this.params.plain.id;
	}

	protected awake(): void {
		this._videoReadyForPlay = false;

		this.hideAllUI();

		// console.log('VimeoComponent.awake();');

		this._renderItem = RenderLoop.Instance.add(this.render, 5);
	}

	private render = (deltaTime: number, totalTime: number): void => {
		this.viewInfo.updateRect();

		if (
			this.viewInfo.isInView() === true && 
			this.getElement().offsetParent !== null &&
			VimeoComponent.getLayerEnabled(this._layer) === true
		) {
			this._timeInView += deltaTime;

			// console.log('this._timeInView : ' +  this._timeInView);

			if (this._timeInView > 0.1) {
				this.loadVideo();

				if (this._videoReadyForPlay === true) {
					if (this._wasInteractedWith === false && this._autoPlay === true) {
						this.Play();
					}
				}
			}
		} else {
			this._timeInView = 0;
			this.unloadVideo();
		}

		if (this._video) {
			this._video
				.getCurrentTime()
				.then(result => {
					this._currentTime = result;

					let timeLeft: number = this._duration - this._currentTime;

					if (isNaN(timeLeft)) {
						timeLeft = 0;
					}

					let date = new Date(null);
					date.setSeconds(timeLeft);

					this._playbackTime.innerText = date.toISOString().substr(14, 5);
				})
				.catch(reason => {});

			this._video
				.getEnded()
				.then(result => {
					if (result === true) {
						this._isPlaying = false;
						this.updateUIToState();
					}
				})
				.catch(reason => {});
		}
	};

	private videoReadyForPlay(): void {
		// console.log('VimeoComponent.videoReadyForPlay(); ' + this._id);

		this._videoReadyForPlay = true;

		// console.log('this._restoreTime : ' + this._restoreTime);
		if (this._restoreTime !== 0) {
			this._video.setCurrentTime(this._restoreTime);
			this._restoreTime = 0;
		}

		if (this._videoUI !== 'none') {
			this._playButton.addEventListener('click', this.onPlay);
		}

		this.updateUIToState();
	}

	protected sleep(): void {
		if (this._renderItem !== null) {
			RenderLoop.Instance.remove(this._renderItem);
			this._renderItem = null;
		}

		if (this._video !== null) {
			this.unloadVideo(true);
			this._video = null;
		}
	}

	protected isReadyForPlay(): boolean {
		return true;
	}

	private onPlay = () => {
		// console.log('VimeoComponent.onPlay();');

		this._wasInteractedWith = true;

		if (!this._isPlaying) {
			this.Play();
		} else {
			this.Pause();
		}
	};

	private Play(): void {
		if (this._isPlaying === true) {
			// console.log('VimeoComponent.Play(); cancel - is playing');
			return;
		}
		// console.log('VimeoComponent.Play();' + this._id);

		this._isPlaying = true;

		// console.log('this._restoreTime : ' + this._restoreTime);

		if (this._restoreTime !== 0) {
			this._video.setCurrentTime(this._restoreTime);
			this._restoreTime = 0;
		}

		this._video
			.play()
			.then(result => {
				// console.log('result : ' + result);
				this.updateUIToState();
			})
			.catch(reason => {
				// console.log('reason : ' + reason);
				this._isPlaying = false;
			});
	}

	private Pause(): void {
		if (this._isPlaying === false) {
			return;
		}

		// console.log('VimeoComponent.Pause(); ' + this._id);

		this._isPlaying = false;
		this._video.pause();

		this.updateUIToState();
	}

	private loadVideo(): void {
		if (this._isVideoLoaded === true) {
			return;
		}

		this._isVideoLoaded = true;

		// console.log('VimeoComponent.loadVideo();');

		this._video = new Player(this._videoContainer, {
			// autoplay: this._autoPlay,
			url: 'https://vimeo.com/video/' + this._id,
			controls: false,
			width: 200,
			height: 200,
			// playsinline: false,
			background: this._autoPlay,
			autopause: false,
			dnt: true,
			title: false
		});

		this._video
			.getDuration()
			.then(result => {
				this._duration = result;
			})
			.catch(reason => {});

		this._video
			.ready()
			.then(result => {
				this.videoReadyForPlay();
			})
			.catch(reason => {});
	}

	private unloadVideo(force: boolean = false): void {
		if (force !== true) {
			if (this._isVideoLoaded === false) {
				return;
			}
		}
		// console.log('VimeoComponent.unloadVideo();');

		// console.log('this._isPlaying : ' + this._isPlaying);
		if (this._isPlaying === true) {
			// console.log('this._currentTime : ' + this._currentTime);
			this._restoreTime = this._currentTime;
		}

		// this line will cause an error
		// this._video?.destroy().catch(err => {});
		this._video = null;
		this._isVideoLoaded = false;
		this._isPlaying = false;
		this._videoReadyForPlay = false;

		if (this._videoUI !== 'none') {
			this._playButton.removeEventListener('click', this.onPlay);
		}

		this.hideAllUI();
	}

	private updateUIToState(): void {
		// console.log('VimeoComponent.updateUIToState();');

		if (this._hideTimeout !== null) {
			clearTimeout(this._hideTimeout);
			this._hideTimeout = null;
		}

		if (this._isPlaying === true) {
			// if (this._placeholder !== null) {
			// 	this._hideTimeout = setTimeout(this.hidePlaceholder, 400);
			// }
			this.hidePlaceholder();

			let iframe: HTMLIFrameElement = this._videoContainer.querySelector('iframe');
			if (iframe !== null) {
				// iframe.style.visibility = 'visible';
				// TweenLite.set(iframe, {opacity: 1});
			}

			if (this._videoUI === 'default') {
				this._pauseIcon.style.display = 'block';
				this._playIcon.style.display = 'none';
				this._playbackTime.style.display = 'block';
			} else if (this._videoUI === 'default') {
				this.hideAllUI();
			}
		} else {
			if (this._placeholder !== null) {
				this._placeholder.style.visibility = 'visible';
			}

			let iframe: HTMLIFrameElement = this._videoContainer.querySelector('iframe');
			if (iframe !== null) {
				// iframe.style.visibility = 'hidden';
				// TweenLite.set(iframe, {opacity: 0});
			}

			if (this._videoUI === 'default') {
				this._pauseIcon.style.display = 'none';
				this._playIcon.style.display = 'block';
				this._playbackTime.style.display = 'block';
			} else if (this._videoUI === 'default') {
				this.hideAllUI();
			}
		}
	}

	private hidePlaceholder = (): void => {
		this._placeholder.style.visibility = 'hidden';
	};

	private hideAllUI(): void {
		this._pauseIcon.style.display = 'none';
		this._playIcon.style.display = 'none';
		this._playbackTime.style.display = 'none';

		// this._placeholder.style.visibility = 'visible';
	}
}
