import Component from "core/ts/system/Component";
import CursorManager from "core/ts/utils/cursor/CursorManager";
import CustomOverDetection from "core/ts/utils/CustomOverDetection";

export default class CursorTarget extends Component {
    protected awake(): void {
        super.awake();

        CustomOverDetection.Instance.add(this.getElement(), this.onMouseOver, this.onMouseOut, this.getSortIndex());
    }

    protected sleep(): void {
        super.sleep();

        CustomOverDetection.Instance.remove(this.getElement());
    }

    private onMouseOver = () => {
        CursorManager.Instance.setCursor(this.getCursorName());
    };

    private onMouseOut = () => {
        CursorManager.Instance.setDefaultCursor();
    };

    protected getSortIndex():number {
        if(this.params.plain.sortindex) {
            return parseInt(this.params.plain.sortindex);
        }
        return 0;
    }

    protected getCursorName():string {
        return this.params.plain.cursorname;
    }
}