//Import for Webpack
import './NewsModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";

export default class NewsModule extends Module {
    protected awake(): void {
        super.awake();

        LinkHandler.Instance.addAllAtags(this.getElement());

        let viewAll = this.qs('.view-all');
        let totalCases = this.qs('.total-cases');

        if(viewAll !== null) {
            totalCases.classList.add('hide-on-mobile');
        }
    }

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }
}