//Import for Webpack
import FeaturedSlideshowModule from 'modules/FeaturedSlideshowModule/FeaturedSlideshowModule';
import './ImageGridSlideshow.scss';
import ComponentNode from 'core/ts/system/ComponentNode';

export default class ImageGridSlideshow extends FeaturedSlideshowModule {

    protected build(): void {
        // console.log("ImageGridSlideshow.build();");
        
        super.build();
        this.qs('.close-button').addEventListener("click", this.onCloseClick);
    }
    
    protected awake(): void {
        super.awake();
        
        // console.log("ImageGridSlideshow.awake();");
    }
    
    protected destroy(): void {
        super.destroy();

        this.qs('.close-button').removeEventListener("click", this.onCloseClick);
    }

    private onCloseClick = ():void => {
        ComponentNode.removeChild(this, true);
    }
}