//Import for Webpack
import Component from 'core/ts/system/Component';
import ITransition from 'core/ts/system/transition/ITransition';
import {Transition} from 'core/ts/system/transition/TransitionController';
import { TweenMax, Expo } from 'gsap';
import ColorUtils from 'core/ts/utils/ColorUtils';
import App from "ts/app";

/**
 * @ignore
 */
export default class ChildTransition extends Component implements ITransition {

    protected awake(): void {
		TweenMax.set(this.getElement(), { width: 0, backgroundColor: ColorUtils.getRandomHex(true) });
	}

	public transitionIn(controller: Transition): void {
        // console.log( "time since main animation started " + controller.totalTimeElapsed );
        // console.log( "number of animation levels in total" + controller.numOfAnimationLevels );
        // console.log( "This animation level " + controller.animationLevel );
        // controller.continue();

		TweenMax.to(this.getElement(), 0.5 + Math.random(), { width: App.RESIZE.windowWidth * 0.5, ease:Expo.easeInOut,  onComplete:() =>{
                controller.done();
            } });
	}

	public transitionOut(controller: Transition): void {
        // controller.continue();
        // TweenMax.to(this.getElement(), 0.5 + Math.random(), { width: 0, ease:Expo.easeInOut,  onComplete:() =>{
        //         controller.done();
        //     } });

        controller.done();
	}

}
